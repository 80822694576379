import React, { useEffect } from 'react'
import { CircularProgress, Drawer, IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Close } from '@material-ui/icons'
import BrowserUtils from '../../user/astrologer/components/UAScripts'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { isDealPurchaseVoucherEnabled } from '../../feature/deal-purchase/components/DealPurchaseUtils'

const useStyles = makeStyles({
  dialog: {
    '& .MuiDrawer-paper': {
      maxWidth: 460,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&.payment-popup--open .payment-popup-close-btn': {
      transform: 'translateY(0)'
    },
    '& .MuiPaper-root': {
      overflow: 'visible',
      borderRadius: '0.5rem 0.5rem 0 0',
    }
  },
  backdropLoader: {
    position: 'absolute',
    zIndex: -1
  },
  frame: {
    border: 0,
    width: '100%',
    height: props => props.fullScreen ? '90vh' : '75vh',
    borderRadius: '0.5rem 0.5rem 0 0',
  },
  closeBtn: {
    marginTop: '-0.75rem',
    position: 'absolute',
    // top: props => props.fullScreen ? '90vh' : '75vh',
    top: '-2.5rem',
    background: '#fff',
    width: '2.5rem',
    height: '2.5rem',
    transition: 'all ease .3s',
    transform: 'translateY(0.75rem)',
    '&:hover': {
      background: '#fff',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.3rem',
    }
  }
})

const PaymentDialog = (props) => {
  const {
    open = false,
    paymentURL = '',
    handleClose = null,
    fullScreen = false,
  } = props
  const classes = useStyles(props)
  const history = useHistory()
  const urlHash = 'back-button--wallet-recharge/payment-dialog' + (isDealPurchaseVoucherEnabled() ? '/deal-purchase' : '')

  const onCloseDrawer = () => {
    browserBackTrigger()
    handleClose()
  }

  useEffect(() => {
    window.addEventListener("hashchange", (e) => {
      if (e.oldURL.endsWith(`#${urlHash}`) && open === true && BrowserUtils.isNewUI()) {
        handleClose()
      }
    })
  })

  useEffect(() => {
    if (open === true && BrowserUtils.isNewUI()) {
      window.location.hash = urlHash
    }
  }, [open])

  const browserBackTrigger = () => {
    if (window.location.hash === `#${urlHash}` && open === true && BrowserUtils.isNewUI()) {
      history.goBack()
    }
  }

  return (
    <>
      <Drawer
        className={clsx(
          "payment-popup",
          classes.dialog,
          fullScreen ? 'full-screen' : '',
          open ? 'payment-popup--open' : ''
        )}
        anchor="bottom"
        open={open}
        onClose={onCloseDrawer}
      >
        <CircularProgress
          className={classes.backdropLoader}
          disableShrink
        />
        <IconButton
          className={clsx(classes.closeBtn, 'payment-popup-close-btn')}
          onClick={onCloseDrawer}
        >
          <Close />
        </IconButton>
        <iframe
          className={classes.frame}
          src={paymentURL}
        />
      </Drawer>
    </>
  )
}

export default PaymentDialog