import React, { useState, useEffect, useRef } from "react"
import Modal from "./Modal"
import userActionData from "../../../../user-actions/mock/UserActionData.mock"
import axios_noheader from "../../../../../common/interceptor_noheader"
import axios from "../../../../../common/interceptor"
import BrowserUtils from "../../../../user/astrologer/components/UAScripts"
import domtoimage from "dom-to-image"

import { useHistory, useLocation } from 'react-router-dom'
import { Button, TextField } from "@material-ui/core"
import Snackbar from "../../../../../common/SnackBarMessage"
import style from './Common.style.module.css'
import userProfileData from "../../../../user-profile/mock/UserProfileData.mock"
import { useRechargeRedirect } from "../RechargeLinkRedirect"
import T2AEventTrack from "../../../../../common/T2AEventTrack";
import SmsSharpIcon from '@material-ui/icons/SmsSharp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import DealPurchaseUtils, { isDealPurchaseVoucherActivated } from "../../../../feature/deal-purchase/components/DealPurchaseUtils"
import DealVoucherPurchaseAfterLoginDialog from "../../../../feature/deal-purchase/components/DealVoucherPurchaseAfterLoginDialog"

const defaultError = { error: false, message: '' }
const otpSecond = 20
let timer = null;
const VerifyNumber = (props) => {
  const [stage, setStage] = useState('before-otp-send')
  const [mobile, setMobile] = useState('')
  const [otp, setOTP] = useState('')
  const [snackBar, setSnackBar] = useState({ message: '', open: false })
  const history = useHistory()
  const { hash } = useLocation()
  const [error, setError] = useState({ mobile: defaultError, otp: defaultError })
  const [second, setSecond] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [screenshot, setScreenshot] = useState('')
  const [otpID, setOtpID] = useState('')
  const [modalWaiting, setModalWaiting] = useState(true)
  const [openModal, setOpenModal] = useState(props.open !== 'undefined' ? props.open : true)
  const [otpSent, setOtpSent] = useState(false)
  const [resentCount, setResentCount] = useState(0)
  const [apiLoginOrRegister, setApiLoginOrRegister] = useState('login')
  const rechargeRedirect = useRechargeRedirect()
  const phoneRef = useRef(null);

  const [dealVoucherPurchaseAfterLoginDialog, setDealVoucherPurchaseAfterLoginDialog] = useState({
    open: false,
    type: 'chat',
    voucherStatus: 0,
    response: {},
    handleClose: () => { }
  })

  // useEffect(() => {
  //   // TODO: AndroidGoogleLogin
  //   // androidBridghe.isReadyToLogin()
  //   if (hash === '#verify-number-AndroidLogin' && BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
  //     window.AndroidBridge.call("isReadyToLogin")
  //     setModalWaiting(true)
  //   } else {
  //     setModalWaiting(false)
  //   }
  // }, [])

  const browserBackTrigger = () => {
    if (window.location.hash === '#verify-mobile-number' && openModal === true) {
      history.goBack()
    }
  }

  useEffect(() => {
    window.addEventListener("hashchange", (e) => {
      if (e.oldURL.endsWith('#verify-mobile-number') && openModal === true) {
        setStage('before-otp-send')
        setOTP('')
        setMobile('')
        props.handleClose()
      }
    })
  })

  useEffect(() => {
    // console.log(props)
    if (props.open !== 'undefined') {
      setOpenModal(props.open === true ? true : false)
    }
  }, [props])

  useEffect(() => {
    const handleDivLoad = () => {
      window.AndroidBridge.call("onPhoneNumberDialogLoaded")
    };

    if (openModal) {
      window.location.hash = "verify-mobile-number";
      setModalWaiting(false)
      const eData = {
        name: 'Screen View Action',
        screenName: 'Chat or Talk Mobile verification'
      }
      trackeventData(eData)
      if (stage === 'before-otp-send' && BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
        if (phoneRef.current) {
          // If the div is already loaded, call the function immediately
          handleDivLoad();
        }
        phoneRef.current?.addEventListener('load', handleDivLoad);

        // Clean up the event listener when the component unmounts or when isDivVisible changes
        return () => {
          phoneRef.current?.removeEventListener('load', handleDivLoad);
        };
      }
      return;
      console.log('verifyNumberAndroidLogin', props.verifyNumberAndroidLogin)
      if (props.verifyNumberAndroidLogin) {
        if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
          window.AndroidBridge.call("isReadyToLogin")
          setModalWaiting(true)
        }
      } else {
        setModalWaiting(false)
      }
    }
  }, [openModal])

  const trackeventData = (data) => {
    const payload = {
      event: {
        name: data.name,
        attributes: {
          'Screen Name': data.screenName,
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'Product': 'COT'
        }
      },
      extra: {
      }
    }

    if (data.sourceScreenName) {
      payload.event.attributes['Source Screen Name'] = data.sourceScreenName;
    }

    if (data.buttonName) {
      payload.event.attributes['Button Name'] = data.buttonName;
    }

    if (data.mobVerification) {
      payload.event.attributes['Mobile Verification'] = data.mobVerification;
    }

    if (data.Devdata) {
      payload.event.attributes['Devdata'] = data.Devdata;

      let profileDevData = {
        dob: 'NA',
        gender: 'NA',
        chartStyle: 'NA',
        tob: 'NA',
        place_name: 'NA',
        name: 'NA'
      }
      try {
        profileDevData = JSON.parse(data.Devdata);
      } catch (e) {
        //not a json
      }

      payload.event.attributes['DOB'] = profileDevData?.dob || 'NA';
      payload.event.attributes['Gender'] = profileDevData.gender || 'NA';
      payload.event.attributes['Horoscope Style'] = profileDevData.chartStyle || 'NA';
      payload.event.attributes['TOB'] = profileDevData.tob || 'NA';
      payload.event.attributes['Place'] = profileDevData.place_name || 'NA';
      payload.event.attributes['User Name'] = profileDevData.name || 'NA';
      payload.event.attributes['Phone Number'] = data.phoneNumber || 'NA';
    }

    if (data.targetName) {
      payload.event.attributes['Target Screen Name'] = data.targetName;
    }

    console.log(payload)
    T2AEventTrack(payload.event, payload.extra);
  }

  const onlyNumbers = (e) => {
    var validChars = userActionData.validChars
    if (!validChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  const updateNumber = (e) => {
    setMobile(e.target.value)
  }

  const runTimer = (s) => {
    clearTimeout(timer)
    if (s >= 0 && s <= otpSecond) {
      setSecond(s)
      timer = setTimeout(() => { runTimer(s - 1) }, 1000)
    }
  }

  const resendOtp = (type) => {
    const eData = {
      name: 'Button Action',
      screenName: 'Chat or Talk Mobile verification',
      buttonName: 'Continue'
    }
    trackeventData(eData);
    if (type === 'whatsapp') {
      setResentCount(1)
    } else {
      setResentCount(2)
    }
    onSendOTP(type)
  }

  const onSendOTP = (otpType) => {
    setLoading(false)
    setOTP('')
    setError({ otp: defaultError, mobile: defaultError })
    setOtpSent(false)
    if (mobile.length !== 10) {
      // setSnackBar({ message: 'Please enter a valid phone number', open: true })
      setError({ ...error, mobile: { error: true, message: 'Please enter a valid phone number' } })
    }
    // else if (this.state.code === "") {
    //   setSnackBar({ message: 'Please select a country code', open: true })
    // } 
    else {

      setError({ otp: defaultError, mobile: defaultError })
      setLoading(true)
      axios_noheader()
        .post("/user/get-otp", {
          phone: mobile,
          code: "+91",
          otp_type: otpType,
          slide: null
        })
        .then((res) => {
          setLoading(false)
          if (res.data.data.success) {
            setOtpID(res.data.data.id)
            setStage('otp-send')
            setOtpSent(true)
            setApiLoginOrRegister(res.data.data?.is_registered ? 'login' : 'register')

            if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
              window.AndroidBridge.call("onScreenLoaded");
            }

            runTimer(otpSecond)
            sessionStorage.setItem("sessionLoginData", JSON.stringify(res.data.data))
          } else {
            setError({ ...error, otp: { error: true, message: res?.data?.data?.message || 'Error occurred, Please try again' } })
          }
        })
        .catch((err) => {
          // sentryError("LOGIN", err);
          setLoading(false)
          setError({ ...error, otp: { error: true, message: 'Error occurred, Please try again' } })
        })
    }
  }

  const onVerify = (apiType = 'login') => {
    sessionStorage.removeItem("sessionLoginData")
    setLoading(true)
    setError({ ...error, otp: defaultError })
    if (!(otp && otp.length === 6)) {
      setError({ ...error, otp: { error: true, message: 'Please enter the OTP' } })
    } else {
      // alert('Verified')
      // onClose()

      const postData = {
        phone: mobile,
        otp: otp,
        is_trai: true,
        is_whatsapp: true,
        data_dom: screenshot,
      }

      // if (apiType === 'register') {
      //postData from android login
      const profileData = getAndroidProfileData();
      if (profileData) {
        let userprofileData = {
          name: profileData.name,
          relation: profileData?.relation || null,
          email: profileData.email,
          phone: mobile,
          gender: profileData.gender,
          placeOfBirth: profileData.place_name,
          dateOfBirth: profileData?.dob.replaceAll('-', '/'),
          timeOfBirth: '10:00 AM',
          horoscopeStyle: 'North Indian',
          profileUrl: null,
          country: profileData?.country || '',
          lat_deg: profileData.latitude_deg,
          lat_min: profileData.latitude_min,
          lat_dir: profileData.lat_dir,
          long_deg: profileData.longitude_deg,
          long_min: profileData.longitude_min,
          long_dir: profileData.long_dir,
          timeCorrectionValue: profileData?.timeCorrectionValue,
          timeCorrection: profileData?.timeCorrection,
          time_zone: profileData?.time_zone,
          app_user_id: profileData?.userId || 0,
        }
        if (profileData?.chartStyle) {
          if (userProfileData.horoscopeStyle[profileData.chartStyle]) {
            userprofileData.horoscopeStyle = userProfileData.horoscopeStyle[profileData.chartStyle]
          }
        } else {
          userprofileData.horoscopeStyle = userProfileData.horoscopeStyle[0]
        }

        if (profileData?.tob) {
          userprofileData.timeOfBirth = new Date('1970-01-01T' + profileData.tob + 'Z')
            .toLocaleTimeString('en-US',
              { timeZone: 'UTC', hour12: true, hour: '2-digit', minute: '2-digit' }
            )
        }

        // COT_1360 - postData update 
        postData.appLanguage = profileData?.appLanguage
        postData.ca_aq_source = profileData?.ca_aq_source
        postData.ca_report_lang = profileData?.ca_report_lang
        postData.ca_prev_payment = profileData?.ca_prev_payment

        postData.userProfile = userprofileData;

      }
      // }

      BrowserUtils.devAlert('postData:activated_voucher_id:' + localStorage.getItem('activated_voucher_id'));
      if (localStorage.getItem('activated_voucher_id')) {
        postData.voucher = localStorage.getItem('activated_voucher_id');
      }


      // TODO: check the api url & what is ID?
      axios()
        .post(`/user/otp/${apiType}/${otpID}`, {
          ...postData,
          ...DealPurchaseUtils.userPostData()
        })
        .then((res) => {
          setLoading(false)
          if (res.data.success) {
            console.log(res.data)
            BrowserUtils.devAlert(`api res ~ /user/otp/${apiType}/${otpID}: ` + JSON.stringify(res.data))

            if (res.data.userProfile.phone && BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
              window.AndroidBridge.call("pushUserPhone", res.data.userProfile.phone);
            }
            if (res.data.userProfile.id && window.smartlook) {
              window.smartlook('identify', res.data.userProfile.id)
            }

            // TODO: check this trackActions
            // if (this.props.location.state.doRegister) {
            //   this.trackActions("fa_wallet_credit", "NA", 75); //free 75 wallet credit
            // }

            localStorage.setItem("userProfileId", res.data.userProfile.id);
            localStorage.setItem("utkn", res.data.token);
            localStorage.setItem("btkn", res.data.bearer);
            localStorage.setItem("name", res.data.userProfile.name);
            localStorage.setItem("email", res.data.userProfile.email);
            localStorage.setItem("phone", res.data.userProfile.phone);
            localStorage.setItem("image", res.data.userProfile.profileUrl);
            localStorage.setItem("walletBalance", res.data.userProfile.balance ? res.data.userProfile.balance : "0");

            if (res.data?.userProfile?.user_status) {
              localStorage.setItem("userStatus", res.data.userProfile.user_status);
            }

            const eData = {
              name: 'Login Action',
              screenName: 'Chat or Talk Mobile verification',
              mobVerification: 'Success'
            }
            trackeventData(eData)

            // TODO: Moengage
            // if (res.data.isLoginElapse) {
            //   if (window.Moengage) {
            //     window.Moengage.add_unique_user_id(res.data.userProfile.phone);
            //   }
            //   history.replace("/app-home")
            // } else if (this.props.location.state.doRegister) {
            //   history.replace("/language-select?register=1");
            // } else if (res.data.isRegister) {
            //   history.replace("/language-select?register=1");
            // } else if (this.props.location.state.historyData) {
            //   if (window.Moengage) {
            //     window.Moengage.add_unique_user_id(res.data.userProfile.phone);
            //   }
            //   window.location.href = decodeURIComponent(this.props.location.state.historyData);
            // } else {
            //   if (window.Moengage) {
            //     window.Moengage.add_unique_user_id(res.data.userProfile.phone);
            //   }
            //   this.props.history.replace("/");
            // }
            BrowserUtils.devAlert(`VerifyNumber:/user/otp/${apiType}/${otpID}:res.data` + JSON.stringify(res.data))
            //remove local crj if logged in
            localStorage.removeItem("userCRJ");
            localStorage.removeItem("sunsignData");
            localStorage.removeItem("moonsignData");
            localStorage.removeItem("dailyPrediciton");
            const refData = rechargeRedirect.getAll();
            refData.voucherData = {
              id: localStorage.getItem('activated_voucher_id'),
              status: res.data.voucher_status
            }
            rechargeRedirect.setData(refData);

            // If refData
            if (refData?.refID !== '') {
              BrowserUtils.devAlert(`refData:refID!=='':` + JSON.stringify(refData))
              localStorage.setItem('DealVoucherFlow', JSON.stringify(refData));
            } else if (localStorage.getItem('DealVoucherFlow') && localStorage.getItem('DealVoucherFlow') !== 'null') {
              const DealVoucherFlow = JSON.parse(localStorage.getItem('DealVoucherFlow'));
              DealVoucherFlow.voucherData = {
                id: localStorage.getItem('activated_voucher_id'),
                status: res.data.voucher_status
              }
              BrowserUtils.devAlert(`refData:from-DealVoucherFlow:` + JSON.stringify(DealVoucherFlow))
              localStorage.setItem('DealVoucherFlow', JSON.stringify(DealVoucherFlow));
            }



            if (apiType === 'register')
              localStorage.setItem("showLangPrefBnr", 1);
            else if (localStorage.getItem("showLangPrefBnr"))
              localStorage.removeItem("showLangPrefBnr");

            //remove local crj if logged in
            // this.trackActions("fa_otp_success", "NA");
            // this.trackActions("fa_registration_success", "NA");

            // Update profile using getAppProfileData
            updateProfileData({ ...res.data.userProfile, "apiType": apiType })
          }
          else if (res?.data?.isRegister) {
            onVerify('register')
          }
          else {
            setError({ ...error, otp: { error: true, message: res.data.message || 'Something went wrong' } })

            const eData = {
              name: 'Login Action',
              screenName: 'Chat or Talk Mobile verification',
              mobVerification: 'Failed'
            }
            trackeventData(eData)


            // if (!this.props.location.state.doRegister && res.data.isRegister) {
            //   let that = this;
            //   window.setTimeout(function () {
            //     window.location.href = "/register?phone=" + that.state.phone;
            //   }, 2000);
            // }
          }
        })
        .catch((err) => {
          setLoading(false)
          // sentryError("OTP_SUBMIT", err)
          BrowserUtils.devConsole(err)
          setError({ ...error, otp: { error: true, message: 'Something went wrong' } })
          const eData = {
            name: 'Login Action',
            screenName: 'Chat or Talk Mobile verification',
            mobVerification: 'Failed',
            tryCatch: true,
          }
          trackeventData(eData)

        })
    }
  }

  const takeScreenshot = () => {
    setError({ ...error, otp: defaultError })
    setOtpSent(false)
    if (!(otp && otp.length === 6)) {
      setError({ ...error, otp: { error: true, message: 'Please enter the OTP' } })
    } else {
      const eData = {
        name: 'Button Action',
        screenName: 'Chat or Talk Mobile verification',
        buttonName: 'Continue'
      }
      trackeventData(eData)
      const node = document.getElementById("wrapperOTP")
      domtoimage
        .toPng(node)
        .then((dataUrl) => {
          console.log(dataUrl)
          setScreenshot(dataUrl)
        })
        .catch(function (error) {
          // sentryError("OTP_SCREENSHOT", error);
          setScreenshot('')
        })
      onVerify(apiLoginOrRegister)
    }
  }

  const getAndroidProfileData = () => {
    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      const profileData = window.AndroidBridge.call("getAppProfileData")
      let userProfile = {};
      try {
        userProfile = JSON.parse(profileData)
      } catch (e) {
        console.log(profileData);
      }
      return userProfile;
    }
    return false;
  }

  const updateProfileData = (userProfile) => {
    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      const profileData = window.AndroidBridge.call("getAppProfileData")
      const eData = {
        name: 'Test EventTA',
        screenName: 'OTP Login',
        targetName: 'Profile data send to COT',
        Devdata: 'NA',
        phoneNumber: userProfile?.phone || ''
      }
      if (profileData) {
        eData.Devdata = profileData;
      }
      trackeventData(eData);
      if (profileData) {
        BrowserUtils.devAlert('AndroidBridge.getAppProfileData: ' + profileData)
        sendProfileData(userProfile, JSON.parse(profileData))
        return true
      }
    }

    BrowserUtils.devAlert(`updateProfileData: ` + JSON.stringify(userProfile))
    setLoading(false)
    setRechargeDirect()
    // onClose()
    return false
  }

  const setRechargeDirect = () => {

    // If deal activated, Add recharge redirect data from DealVoucherFlow
    if (localStorage.getItem('activated_voucher_id') && localStorage.getItem('DealVoucherFlow')) {
      localStorage.setItem('recharge_redirect', localStorage.getItem('DealVoucherFlow'));
    }

    if (rechargeRedirect.isValid() && rechargeRedirect.getItem('taskData')) {
      props.handleClose()
      // history.push('/app-home')
      window.processDynamicNotification(rechargeRedirect.getItem('taskData'))
      rechargeRedirect.delete();
      return
    } else if (rechargeRedirect.isValid() && (rechargeRedirect.getItem('astrologerID') || rechargeRedirect.getItem('afterLogin'))) {
      props.handleClose()
      const type = rechargeRedirect.getItem('type') || 'chat'
      // window.location.href = `/?recharge_redirect=1#${type}request`
      if (isDealPurchaseVoucherActivated()) {
        props.afterVerify({
          path: 'deal/voucher/activated'
        });
        localStorage.removeItem('activated_voucher_id');

        // alert('Show voucher dialog here');
        // const resData = rechargeRedirect.getAll();
        // console.log('resData:', resData)
        // setDealVoucherPurchaseAfterLoginDialog({
        //   ...dealVoucherPurchaseAfterLoginDialog,
        //   open: true
        // })
        // 

        // Remove activated_voucher_id to remove voucher activated id
      } else {
        history.push(`/?recharge_redirect=1#${type}request`)
      }
      return
    } else if (!rechargeRedirect.isValid()) {
      refreshHome()
    }
  }

  const rechargeRedirectDealPurchase = () => {
    history.push(`/?recharge_redirect=1&activated_voucher_id=${isDealPurchaseVoucherActivated()}#${rechargeRedirect.getItem('type') || 'chat'}request`)
  }

  const sendProfileData = (profile, details) => {
    setLoading(true)
    console.log('sendProfileData:profile, details', profile, details)

    /*
      profile data sample

      balance: "75",
      email: "test@test.com",
      name: "Test",
      phone: "9388820459",
      profileUrl: null
    */

    if (details?.name) {
      // Allow only characters & spaces
      details.name.replace(/([a-zA-Z ])/g, "")
        .split('')
        .map(k => {
          details.name = details.name.replaceAll(k, '')
          return k
        })
    } else {
      details.name = " "
    }

    // Date of birth
    if (details?.dob) {
      details.dob = details?.dob.replaceAll('-', '/')
    }

    // Email
    if (!details?.email && profile?.email) {
      details.email = profile.email
    }

    // Phone
    if (!details?.phone && profile?.phone) {
      details.phone = profile.phone
    }

    // Time of birth
    if (details?.tob) {
      details.tob = new Date('1970-01-01T' + details.tob + 'Z')
        .toLocaleTimeString('en-US',
          { timeZone: 'UTC', hour12: true, hour: '2-digit', minute: '2-digit' }
        )
    }

    // Latitude
    if (details?.latitude_deg) {
      details.latitude_deg = details.latitude_deg
      // if (details?.latitude_min) {
      //   details.latitude_deg = `${details.latitude_deg}.${details.latitude_min}`
      // }
    }

    // Longitude
    if (details?.longitude_deg) {
      details.longitude_deg = details.longitude_deg
      // if (details?.longitude_min) {
      //   details.longitude_deg = `${details.longitude_deg}.${details.longitude_min}`
      // }
    }

    // Profile url
    if (!details?.profileUrl && profile?.profileUrl) {
      details.profileUrl = profile?.profileUrl
    }

    // ChartStyle
    if (details?.chartStyle) {
      if (userProfileData.horoscopeStyle[details.chartStyle]) {
        details.chartStyle = userProfileData.horoscopeStyle[details.chartStyle]
      }
    } else {
      details.chartStyle = userProfileData.horoscopeStyle[0]
    }

    setError({ ...error, otp: defaultError })
    const postData = {
      name: details?.name, // Test
      relation: details?.relation || '', // BLANK
      gender: details?.gender, // Male
      email: details?.email, // test@test.com
      phone: details?.phone, // 9388820459
      horoscopeStyle: details?.chartStyle, // South Indian
      dateOfBirth: details?.dob, // 20/06/1992 (DD/MM/YYYY)
      placeOfBirth: details?.place_name, // Kochi, Kerala, India
      timeOfBirth: details?.tob, // 11:50 AM
      profileUrl: details?.profileUrl || null,
      //lat: details?.latitude_deg + '.' + details?.latitude_min,
      //long: details?.longitude_deg + '.' + details?.longitude_min,
      lat_deg: details?.latitude_deg,
      lat_min: details?.latitude_min,
      lat_dir: details?.lat_dir,
      long_deg: details?.longitude_deg,
      long_min: details?.longitude_min,
      long_dir: details?.long_dir,
      country: details?.country || '',
      app_user_id: details?.userId,
      app_language: details?.appLanguage,
      api_type: profile.apiType,
      timeCorrectionValue: details?.timeCorrectionValue,
      timeCorrection: details?.timeCorrection,
      time_zone: details?.time_zone
    }

    BrowserUtils.devAlert('updateProfile:postData: ' + JSON.stringify(postData))
    axios()
      .post("/profile/user/edit", postData)
      .then((res) => {
        BrowserUtils.devAlert('updateProfile:/profile/user/edit:result: ' + JSON.stringify(res))
        if (res.data.success) {
          // if (pageType === "userProfile") {

          // if (BrowserUtils.isAWebview) {
          //   if (typeof window.AndroidBridge !== "undefined") {
          //     //let myVar = window.AndroidBridge.call("getAndroidVersion");
          //     window.AndroidBridge.call(
          //       "pushUserProfileDetails",
          //       JSON.stringify({
          //         name: details.name,
          //         relation: details.relation,
          //         gender: details.gender,
          //         email: details.email,
          //         phone: details.phone,
          //         horoscopeStyle: details.horoscopeStyle,
          //         dateOfBirth: tempDate,
          //         placeOfBirth: query,
          //         timeOfBirth: details.timeOfBirth,
          //         profileUrl: data ? data : details.profileUrl ? details.profileUrl : "",
          //         lat: details.lat,
          //         long: details.long,
          //         country: details.country,
          //       })
          //     );
          //   }
          // } else {
          //   if (window.Moengage) {
          //     window.Moengage.add_unique_user_id(details.phone);
          //     window.Moengage.add_first_name(details.name);
          //     window.Moengage.add_user_name(details.name);
          //     window.Moengage.add_email(details.email);
          //     window.Moengage.add_mobile(Number(details.phone));
          //     window.Moengage.add_gender(details.gender);
          //     window.Moengage.add_user_attribute("MOBILE_NUMBER", Number(details.phone));
          //     window.Moengage.add_user_attribute("DATE_OF_BIRTH", tempDate);
          //     window.Moengage.add_user_attribute("PLACE_OF_BIRTH", query);
          //     window.Moengage.add_user_attribute("HOROSCOPE_STYLE", details.horoscopeStyle);
          //     window.Moengage.add_user_attribute("TIME_OF_BIRTH", details.timeOfBirth);
          //   }
          // }

          localStorage.setItem("name", details?.name);
          localStorage.setItem("email", details?.email);
          localStorage.setItem("phone", details?.phone);
          localStorage.setItem("image", details?.profileUrl);
          if (postData.app_user_id) {
            localStorage.setItem('userid', postData.app_user_id);
          }
          //remove local crj if logged in
          localStorage.removeItem("userCRJ");
          localStorage.removeItem("sunsignData");
          localStorage.removeItem("moonsignData");
          localStorage.removeItem("dailyPrediciton");
          //remove local crj if logged in
          // }


          // window.setTimeout(() => {
          //   moengage("USERPROFILE_CREATED");
          //   if (props.location.search !== "") {
          //     let redirectPath = QueryString().pathname;
          //     if (redirectPath === "/my-account") {
          //       props.history.replace("/my-account");
          //       //window.location.href = "/my-account";
          //     } else if (redirectPath === "/my-wallet") {
          //       props.history.replace("/my-wallet");
          //       //window.location.href = "/my-wallet";
          //     } else if (redirectPath === "/") {
          //       props.history.replace("/");
          //       //window.location.href = "/";
          //     } else if (redirectPath && redirectPath.indexOf("/astrologer") >= 0) {
          //       props.history.replace(redirectPath);
          //       //window.location.href = redirectPath;
          //     } else {
          //       window.location.href = "/";
          //     }
          //   } else {
          //     //props.history.replace("/");
          //     window.location.href = "/";
          //   }
          // }, 1000);
          // refreshHome();
        } else {
          console.log('edit error', res)
          setLoading(false)
          // setError({ ...error, otp: { error: true, message: res.data.message || 'Something went wrong' } })
        }
        setLoading(false)
        setRechargeDirect()
        // onClose()
      })
      .catch((err) => {
        console.log('catch error', err)
        setLoading(false)
        onClose()
        // setError({ ...error, otp: { error: true, message: 'Please try again' } })
        // sentryError("USER_PROFILE_SUBMIT_DETAILS", err);
      });
  };

  const onClose = () => {
    // if (props.open !== 'undefined') {
    //   setOpenModal(false)
    // } {
    //   history.push('/app-home')
    // }
    browserBackTrigger()
    props.handleClose()
  }

  const refreshHome = () => {
    console.log('refreshHome')
    window.location.href = '/app-home'
    // history.push('/app-home')
    // window.location.href = '/'
  }

  return (
    <>
      <Modal
        // type="bottom-drawer"
        overlayClose={props?.overlayClose === 'disabled' ? false : second === -1}
        loading={loading}
        waiting={modalWaiting}
        open={props.open}
        handleClose={props.handleClose}
      >
        <button hidden id="continueAppLoginComplete" onClick={() => setModalWaiting(false)}></button>
        <Modal.Header type="invert">Verify Mobile Number</Modal.Header>

        {stage === 'before-otp-send' &&
          <>
            <Modal.Content>
              <form style={{ textAlign: 'center' }}>
                <p className={style.infoText}>Please enter your mobile number and complete the OTP verification.</p>
                <div style={{ paddingRight: '1rem' }} ref={phoneRef}>
                  <span className={style.countryCode}>+91</span>
                  <TextField
                    error={error.mobile.error}
                    helperText={error.mobile.message}
                    className={style.phoneNumber}
                    value={mobile}
                    type="tel"
                    variant="outlined"
                    placeholder="0000000000"
                    // phone={this.state.phone}
                    inputProps={{ maxLength: 10 }}
                    onKeyDown={onlyNumbers}
                    onChange={(e) => updateNumber(e)}
                    id="verifyOTPPhoneNumberInput"
                  />
                </div>
                <div id='indianNumberPrompt' style={{ display: 'none' }} onClick={() => {
                  setError({ ...error, mobile: { error: true, message: 'Please enter a valid Indian number' } })
                }}></div>
              </form>
            </Modal.Content>

            <Modal.Action>
              <Button variant="contained" onClick={() => { setError({ otp: defaultError, mobile: defaultError }); setOtpSent(false); onClose() }}>
                Cancel
              </Button>
              <Button
                id="verifyOTPPhoneNumberButton"
                className="primary"
                variant="contained"
                onClick={() => {
                  const eData = {
                    name: 'Screen View Action',
                    screenName: 'Chat or Talk OTP Screen',
                    sourceScreenName: 'Verify Number'
                  }
                  trackeventData(eData);
                  onSendOTP('phone');
                  setResentCount(0)
                }
                }>
                Continue
              </Button>
            </Modal.Action>
          </>
        }

        {stage === 'otp-send' &&
          <>
            <Modal.Content>
              <form id="wrapperOTP" style={{ textAlign: 'center' }}>
                <p className={style.infoText}>Enter 6 digits OTP sent to {mobile}
                  <br />
                  {second > 0 && resentCount < 2 && <span className={style.otpInfoText}>Resend OTP in {second}s</span>}
                  {second === 0 && resentCount == 1 && <Button className={style.otpLink} onClick={() => {
                    resendOtp('phone')
                  }}>Resend OTP via SMS</Button>}
                  {second === 0 && resentCount == 0 && <Button className={style.otpLink} onClick={() => {
                    resendOtp('whatsapp')
                  }}>Resend OTP via Whatsapp</Button>}
                </p>
                <div>
                  <TextField
                    value={otp}
                    error={error.otp.error}
                    helperText={error.otp.message}
                    className={style.otpField}
                    autoFocus
                    id="loginOTPInput"
                    type="tel"
                    variant="outlined"
                    placeholder="000000"
                    inputProps={{ maxLength: 6 }}
                    onKeyDown={onlyNumbers}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                </div>
                {otpSent && (resentCount === 1 || resentCount === 2) && (
                  <div style={{ color: 'green', marginTop: '16px' }}>
                    {resentCount === 1 ? <WhatsAppIcon style={{ verticalAlign: 'middle' }} /> : <SmsSharpIcon style={{ verticalAlign: 'middle' }} />}
                    <span style={{ marginLeft: '8px', verticalAlign: 'middle' }}>
                      The OTP has been resent to your {resentCount === 1 ? 'WhatsApp' : 'Number'}.
                    </span>
                  </div>
                )}
              </form>
            </Modal.Content>

            <Modal.Action>
              <Button disabled={second > 0} variant="contained"
                onClick={() => {
                  setStage('before-otp-send');
                  setOtpSent(false)
                  const eData = {
                    name: 'Button Action',
                    screenName: 'Chat or Talk Mobile verification',
                    buttonName: 'Cancel'
                  }
                  trackeventData(eData)
                }
                }>
                Back to Edit
              </Button>
              <Button className="primary" variant="contained" id="otpSubmitButton" onClick={takeScreenshot}>
                Verify
              </Button>
            </Modal.Action>
          </>
        }

      </Modal>


      <DealVoucherPurchaseAfterLoginDialog
        open={dealVoucherPurchaseAfterLoginDialog.open}
        voucherStatus={dealVoucherPurchaseAfterLoginDialog.voucherStatus}
        close={() => {
          setDealVoucherPurchaseAfterLoginDialog(deal => ({ ...deal, open: false }))
        }}
        triggerActiveActionBtn={rechargeRedirectDealPurchase}
      />

      <Snackbar
        vertical="top"
        horizontal="center"
        message={snackBar.message}
        closeError={() => setSnackBar({ ...snackBar, open: false })}
        open={snackBar.open}
      />
    </>
  )
}

export default VerifyNumber