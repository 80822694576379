import BrowserUtils from "../../../user/astrologer/components/UAScripts";
import DealPurchaseConfig from "./DealPurchaseConfig";

const excludeEmailIds = [
]

const DealPurchaseUtils = {
  userPostData: (data = {}) => {
    let profileData = {
      ca_prev_payment: 0,
    }
    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      const profileDataJSON = window.AndroidBridge.call("getAppProfileData")
      console.log('DealPurchaseUtils:profileDataJSON', profileDataJSON)
      try {
        const _profileData = JSON.parse(profileDataJSON);
        // _profileData.ca_prev_payment = '1' // Test
        if (_profileData?.ca_prev_payment
          && !excludeEmailIds.includes(_profileData?.email) // Exclude emails
        ) {
          profileData.ca_prev_payment = _profileData?.ca_prev_payment
        }
        console.log('DealPurchaseUtils:_profileData', _profileData)
      } catch (er) { console.log('profileDataJSON:parse:error', er) }
    }

    return {
      ...data,
      ca_prev_payment: profileData?.ca_prev_payment || 0,
    }
  }
}


export const isDealPurchaseVoucherEnabled = () => {
  return DealPurchaseConfig.has_voucher_enabled && !BrowserUtils.isLoggedIn()
}

export const isDealPurchaseVoucherActivated = () => {
  return localStorage.getItem('activated_voucher_id')
}

export default DealPurchaseUtils