import React, { useState } from "react"
import Modal from "./Modal"

import { Avatar, Button, Dialog, TextField } from "@material-ui/core"
import style from './AstrologerModal.style.module.css'
import AstrologerUtils from "../AstrologerUtils"
import defaultImage from "../../../../../common/default_profile_image.svg"
import CallIcon from '@material-ui/icons/Call';
import ChatBubble from '@material-ui/icons/ChatBubble';
import { useEffect } from "react"
import axios from "../../../../../common/interceptor"
import { MIN_BALANCE_MINS } from "../../../../../app.config"
import { useHistory } from 'react-router-dom'
import { useRechargeRedirect } from "../RechargeLinkRedirect"
import AstrologerCallDialog from "../AstrologerCallDialog"
import UserProfileForPreCallChat from "../../../../user-profile/components/UserProfileForPreCallChat"
import { ContentFreeChatInfo, ContentPaidChatInfo } from "../StaticContent"
import { WalletUtils } from "../../../../../route/WalletComponent"


const AstrologerLoadingModal = (props) => {
  const [astrologer, setAstrologer] = useState({})
  const [opt, setOpt] = useState({})
  const [queueStatus, setQueueStatus] = useState('')
  const [queue, setQueue] = useState({})
  const [requiredPts, setRequiredPts] = useState(0)
  const history = useHistory()
  const rechargeRedirect = useRechargeRedirect()
  const [birthDetailsForm, setBirthDetailsForm] = useState(null)
  const [isFreeConsultation, setIsFreeConsultation] = useState(false)

  const timeOutDelay = 1000

  const getMessages = () => {

    if (astrologer.status === 'busy') {
      const awayStr = astrologer.away_until_mins ? ' for ' + astrologer.away_until_mins + ' mins' : ''
      return `Sorry, ${astrologer.name || astrologer.full_name} is currently busy${awayStr}. Please try again later.`
    }

    if (astrologer.status === 'offline') {
      return `Sorry, ${astrologer.name || astrologer.full_name} is offline now.`
    }

    if (astrologer.status === 'away') {
      const awayStr = astrologer.away_until_mins ? ' for ' + astrologer.away_until_mins + ' mins' : ' for some time'
      return `Sorry, ${astrologer.name || astrologer.full_name} is away${awayStr}.`
    }

    return `Sorry, ${astrologer.name || astrologer.full_name} is not available now.`
  }

  const checkAstrologerAvailability = () => {
    if (queueStatus !== 'check-astrologer-availability') {
      return
    }

    axios()
      .post(`/astrologers/${astrologer.profile_url}`)
      .then((res) => {
        if (res.data.success) {
          const profile = res.data.data
          profile.free_minutes = profile[`free_${opt.actionType}_minutes`]
          profile.astrologerFee = profile.perMinutePrice
          // profile.astrologerFee = 10000
          setAstrologer({ ...astrologer, ...profile })
          setTimeout(async () => {
            const walletBalance = parseInt(localStorage.getItem("walletBalance")) || 0
            const minWalletBalance = profile.astrologerFee * MIN_BALANCE_MINS
            const lowBalance = !(profile.free_minutes > 0 || minWalletBalance <= walletBalance)
            const diffBalance = minWalletBalance - walletBalance > 0 ? minWalletBalance - walletBalance : minWalletBalance
            // console.log({
            //   walletBalance,
            //   free_minutes: profile.free_minutes,
            //   astrologerFee: profile.astrologerFee,
            //   MIN_BALANCE_MINS,
            //   'profile.astrologerFee * MIN_BALANCE_MINS:': profile.astrologerFee * MIN_BALANCE_MINS,
            //   lowBalance
            // })
            if (res.data.data.status === 'online') {
              if (lowBalance) {
                setRequiredPts(diffBalance)
                setQueueStatus('low-balance')
              } else {

                // const data = await getAllAstrologers()
                // if (data?.have_free_consult && data.have_free_consult === 1) {
                //   setIsFreeChat(true)
                // }

                setQueueStatus('ready-to-connect')
              }
            } else {
              setQueueStatus('astrologer-not-online')
            }
          }, timeOutDelay)
        }
      })
      .catch((err) => {
        console.log(err)
      });
  }

  const getAllAstrologers = async () => {
    return new Promise((resolve, reject) => {
      axios()
        .post('/astrologers/all/ma')
        .then((res) => {
          if (res && res.data.success && res.data.data) {
            resolve(res.data)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }


  useEffect(() => {
    // console.log('AstrologerLoadingModal:', {
    //   'rechargeRedirect.isValid()': rechargeRedirect.isValid(),
    //   'props.opt.astrologer': props.opt.astrologer.astrologerID,
    //   "rechargeRedirect.getItem('astrologerID')": rechargeRedirect.getItem('astrologerID'),
    //   'condition': rechargeRedirect.isValid() && props.opt.astrologer.astrologerID === rechargeRedirect.getItem('astrologerID')
    // })
    // if (rechargeRedirect.isValid() && props.opt.astrologer.astrologerID === rechargeRedirect.getItem('astrologerID')) {
    setOpt(props.opt)
    setAstrologer({
      id: props.opt.astrologer.astrologerID,
      full_name: props.opt.astrologer.astrologerName,
      profile_url: props.opt.astrologer.astrologerProfileURL,
      profile_image: AstrologerUtils.createThumbImageURI(props.opt.astrologer.astrologerID)
    })

    if (rechargeRedirect.getItem('birthDetailsRequired') === true) {
      window.location.hash = "confirm-birth-details";
      setBirthDetailsForm(true)
    } else {
      setBirthDetailsForm(false)
    }

    checkFreeConsultation()
    // }
  }, [props.modalId])

  const checkFreeConsultation = () => {
    axios()
      .post(`/consultations/free-consult-status`, {
        astrologerId: props.opt.astrologer.astrologerID
      })
      .then((res) => {
        if (res.data.success) {
          if (res.data.freeConsult === true) {
            setIsFreeConsultation(true)
          }
        }
      })
  }

  window.addEventListener("hashchange", (e) => {
    if (e.oldURL.endsWith('#confirm-birth-details') && birthDetailsForm === true) {
      onClose()
    }
  });

  useEffect(() => {
    if (birthDetailsForm === false) {
      setQueueStatus('check-astrologer-availability')
    }
  }, [birthDetailsForm])


  useEffect(() => {
    // console.log(queueStatus)
    if (queueStatus === 'check-astrologer-availability') {
      setTimeout(checkAstrologerAvailability, timeOutDelay)
      setQueue({ text: 'Checking astrologer availability' })
    }

    if (queueStatus === 'low-balance') {
      setQueue({ error: `Please recharge for at least ${requiredPts} points to start this consultation.` })
    }

    if (queueStatus === 'ready-to-connect') {
      setQueue({ text: 'Please wait for a while' })
      clearRechargeRedirectData()
      if (props.opt.onComplete) {
        setTimeout(props.opt.onComplete, timeOutDelay)
      }
    }

    if (queueStatus === 'astrologer-not-online') {
      const activeConsultationEle = document.getElementById('chat-active-consultation')
      if (activeConsultationEle && activeConsultationEle.getAttribute('data-astrologer-id') == props.opt.astrologer.astrologerID) {
        setQueue({ text: 'Please wait for a while' })
        setTimeout(() => { activeConsultationEle.click() }, timeOutDelay)
      } else {
        setQueue({ error: getMessages() })
      }
    }

  }, [queueStatus])

  const openWallet = () => {
    WalletUtils.setType(WalletUtils.getDefaultType())
    window.location.href = `/user-wallet?all-recharge-options=true#from-astrologer-loading-modal`
    // history.push('/user-wallet?all-recharge-options=true#from-ast rologer-loading-modal')
  }

  const onClose = () => {
    setBirthDetailsForm(null)
    setOpt({ ...props.opt, open: false })
    clearRechargeRedirectData()
  }

  const clearRechargeRedirectData = () => {
    rechargeRedirect.delete()
  }

  const ModalContent = () => {
    return <Modal.Content>
      <div className={style.astrologerModalContent}>
        <Avatar
          className="pic"
          alt={astrologer.full_name}
          src={astrologer.profile_image}
        />
        {queue?.text && <div className="time threeDotAnimation">{queue.text}</div>}
        <div className="name">{astrologer?.full_name}</div>
        {
          queue?.text
            && queue.text === 'Please wait for a while'
            ?
            <>
              {isFreeConsultation ?
                <div className="free_cc_external">
                  <ContentFreeChatInfo />
                  <strong className="free-tag">Free {opt.actionType} for 5 mins</strong>
                </div>
                :
                <div className="free_cc_external">
                  <ContentPaidChatInfo />
                </div>
              }
            </>
            :
            <></>
        }
        {queue?.error &&
          <>
            <div className="error-availability">{queue.error}</div>
            <div className="buttons">
              {queueStatus === 'low-balance' ?
                <Button className="btn btn-recharge" variant="contained" onClick={openWallet}>
                  Recharge Now
                </Button>
                :
                <Button className="btn btn-close" variant="contained" onClick={onClose}>
                  Try another astrologer
                </Button>
              }
            </div>
          </>
        }
      </div>
    </Modal.Content>
  }

  return (
    <>

      {birthDetailsForm ?
        <Dialog
          open
          className="astrologerDialog CCDialog overlay-birth-form"
        >
          <div className="dialog">
            <div className="UPFormPreCC">
              <div className={"header"}>Birth Details</div>
              <UserProfileForPreCallChat
                location={{
                  pathname: '/userprofile'
                }}
                handleClose={() => {
                  onClose();
                }}
                continue={() => {
                  setBirthDetailsForm(false)
                }}
              />
            </div>
          </div>
        </Dialog>
        :
        <div className={style.astrologerLoadingModal}>
          <Modal
            type="normal"
            open={opt.open}
          >
            {opt.actionType === 'call' &&
              <>
                <Modal.Header invert>Calling... <CallIcon /></Modal.Header>
                <ModalContent />
              </>
            }
            {opt.actionType === 'chat' &&
              <>
                <Modal.Header invert>Requesting Chat... <ChatBubble /></Modal.Header>
                <ModalContent />
              </>
            }
          </Modal >
        </div>
      }
    </>
  )
}

export default AstrologerLoadingModal