import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/CheckCircle";
import Button from "@material-ui/core/Button";
import { SUPPORT_NUMBER, T2A_SUPPORT_NUMBER } from "../../../app.config";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import { useRechargeRedirect } from "../../user/astrologer/components/RechargeLinkRedirect";
import { CallRounded, WhatsApp } from "@material-ui/icons";
import { BotMessage, getURLParams, initChat, notifyChat } from "../../user/astrologer/components/chat/ChatUtls";
import clsx from "clsx";
import DealPurchase from "../../feature/deal-purchase/components/DealPurchase";
import { isDealPurchaseVoucherEnabled } from "../../feature/deal-purchase/components/DealPurchaseUtils";
import VerifyNumber from "../../user/astrologer/components/Modal/VerifyNumber";
import DealVoucherPurchaseAfterLoginDialog from "../../feature/deal-purchase/components/DealVoucherPurchaseAfterLoginDialog";
import AstrologerLoadingModal from "../../user/astrologer/components/Modal/AstrologerLoadingModal";
import axios from "../../../common/interceptor";
import FA_localStorage from "../../../common/util/FA_localStorage";
import md5 from "md5";
import ChatNotificationTrigger from "../../user/astrologer/components/chat/ChatNotificationTrigger";
import { ChatWindowStateProvider, ChatWindowStateSetterProvider } from "../../user/astrologer/components/chat/ChatWindowContext";
import { isChatEnabled } from "../../user/astrologer/components/AstrologerUtils";
import Conversations from "../../user/astrologer/components/chat/Conversations";
import { WalletUtils } from "../../../route/WalletComponent";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiDialog-paper": {
      borderRadius: 12,
    },
  },
  paymentWrap: {
    padding: "20px",
  },
  paperCenter: {
    textAlign: "center",
  },
  closeModal: {
    fontSize: "20px",
    stroke: "black",
    display: "flex",
    strokeWidth: "1px",
    position: "absolute",
    right: "20px",
    top: "20px",
  },
  errorPara: {
    color: "#5d5d5d",
    margin: "20px 0px",
    lineHeight: "normal",
    textAlign: "left",
    fontSize: "0.9rem",
  },
  errorText: {
    fontWeight: "bold",
    marginTop: "-5px",
    textTransform: 'capitalize',
  },
  errorIcon: {
    marginTop: "10px",
    color: "red",
    fontSize: "55px",
  },
  errorButton: {
    backgroundColor: "#f56307",
    color: "white",
    fontWeight: "bold",
    width: "160px",
    boxShadow: "none",
    borderRadius: "40px",
    padding: "10px",
    "&:hover, &:focus": {
      backgroundColor: "#f56307 !important",
    },
  },
  warningIcon: {
    marginTop: "20px",
    color: "white",
    stroke: "#f5b707",
    fontSize: "55px",
  },
  warningButton: {
    backgroundColor: "#f5b707",
    color: "white",
    fontWeight: "bold",
    width: "90%",
    borderRadius: "40px",
    padding: "10px",
    "&:hover, &:focus": {
      backgroundColor: "#f5b707 !important",
    },
  },
  successIcon: {
    marginTop: "20px",
    color: "rgb(109 202 78)",
    fontSize: "55px",
  },
  successNewIcon: {
    color: "var(--clr-green)",
  },
  successButton: {
    backgroundColor: "#00c180",
    color: "white",
    fontWeight: "400",
    fontSize: "0.9rem",
    boxShadow: "none",
    width: "90%",
    borderRadius: "40px",
    padding: "10px",
    "&:hover, &:focus": {
      backgroundColor: "#00c180 !important",
    },
  },
  errorLabel: {
    padding: "40px 40px 4px 40px",
    fontSize: "0.75rem",
    color: "#f00",
  },
  dealDefaultVoucherScreen: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dealDefaultVoucherScreenHolder: {
    width: '100%',
    maxWidth: 250,
    textAlign: 'center',
  }
}));

export default function WalletModal(props) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(props.modalOpen);
  const rechargeRedirect = useRechargeRedirect()
  const buttonNames = {
    default: 'CLOSE',
    chat: 'CONTINUE CHAT',
    call: 'CONTINUE CALL',
  }
  const chatID = rechargeRedirect.getItem('chatID')
  const params = new URLSearchParams(window.location.search)
  const paymentStatus = params.get('status')
  const [verifyNumber, setVerifyNumber] = useState({
    open: false,
    astrologer: {},
  })
  const [voucherDialog, setVoucherDialog] = useState({
    open: false,
    voucherStatus: 0,
  })
  const [showContinueLoginButton, setShowContinueLoginButton] = useState(false);

  const [astrologerConsultLoading, setAstrologerConsultLoading] = useState({});
  const [chatWindowData, setChatWindowData] = useState({});
  const verifyRef = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const [hashChangeStack, setHashChangeStack] = useState({
    hashStack: [],
    max: 0,
  });

  const onButton = async () => {
    if (rechargeRedirect.isValid()
      && (rechargeRedirect.getItem('astrologerID') || DealPurchase.isPaymentRequest() || isLastOrderIsDealPurchase())
      && props.status === "success"
    ) {
      if (chatID) {
        await BotMessage.send(chatID, 'instant-payment-initializing-chat')
      }
      const type = rechargeRedirect.getItem('type') || 'chat'
      if (isDealPurchaseVoucherEnabled()) {
        setOpen(false);

        if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {

          if (window.isAppProfileDataHasEmail()) {
            BrowserUtils.devAlert('isAppProfileDataHasEmail - Open verify popup')
            setVerifyNumber(v => { return { ...v, open: true } });
            return
          }
          BrowserUtils.devAlert('isReadyToLogin')
          window.AndroidBridge.call("isReadyToLogin")
        } else {
          setVerifyNumber(v => { return { ...v, open: true } });
        }

      } else if (rechargeRedirect.getItem('astrologerID')) {
        window.location.href = `/?recharge_redirect=1#${type}request`
      } else {
        window.location.href = `/`
      }
      return
    }
    setOpen(false);
    window.history.replaceState(null, "", "/");
    window.location.href = window.AndroidBridge == undefined ? "https://mobile.clickastro.com/cot-payment-home" : (props.targetURI || "/my-wallet?retry=1");
  };

  useEffect(() => {
    // ShowContinueLoginButton after 3 seconds
    setTimeout(() => {
      BrowserUtils.devAlert('ShowContinueLoginButton')
      setShowContinueLoginButton(true);
    }, 3000);

    const hashChange = (e) => {

      if (paymentStatus
        && paymentStatus.toString().toLowerCase() === 'success') {
        setHashChangeStack(stack => {

          const hashStack = stack.hashStack
          const hash = e.newURL.split('#')[1]

          if (!hashStack.includes(hash)) {
            hashStack.push(hash)
          } else if (hashStack.includes(hash)) {
            hashStack.splice(hashStack.indexOf(hash), 1)
          }

          // console.log('bp:hashStack:', hashStack)

          if (stack.max !== 0 && stack.max > hashStack.length) {
            // console.log('bp:hashStack:', 'back detected')
            gotoListing();
          }

          return {
            hashStack,
            max: stack.max < hashStack.length ? hashStack.length : stack.max
          }
        })
      }
    }
    window.addEventListener("hashchange", hashChange);
    return () => {
      window.removeEventListener("hashchange", hashChange);
    }
  }, []);

  useEffect(async () => {
    // console.log({ chatID, props, isOpen })
    if (chatID && isOpen === true) {
      if (props.status === "success") {
        await BotMessage.send(chatID, 'instant-payment-success')
      } else {
        await BotMessage.send(chatID, 'instant-payment-failed')
      }
    }
  }, [props.status, isOpen])

  useEffect(() => {
    if (props.modalOpen) {
      // window.location.hash = "#back-button--wallet-recharge/"; // changed from #backtowallet for a temp fix.
      if (paymentStatus === null) {
        window.location.hash = "#backtowallet";
      } else {
        window.location.hash = "#back-button--wallet-recharge/";
      }
      setOpen(true);
    }
  }, [props.modalOpen]);

  // Test cases
  useEffect(async () => {

    // Voucher contents by status
    if (getURLParams('test_voucher_status')) {
      setTimeout(() => {
        const voucherStatus = parseInt(getURLParams('test_voucher_status'));
        // const voucherStatus = 1
        console.log('voucherStatus:', voucherStatus)
        setVoucherDialog(v => {
          return {
            ...v,
            open: true,
            voucherStatus,
          }
        })
        setOpen(false)
      }, 1000)
    }

    // Voucher contents by status
    if (getURLParams('test_chat_init')) {
      setTimeout(() => {
        triggerActiveActionBtn()
        setOpen(false)
      }, 1000)
    }
  }, [window.location.search])

  const getButtonName = () => {
    if (isDealPurchaseVoucherEnabled()) {
      return 'Continue';
    }
    let key = 'default'
    if (props.status === "success" && rechargeRedirect.getItem('astrologerID')) {
      key = buttonNames[rechargeRedirect.getItem('type')] ? rechargeRedirect.getItem('type') : 'default'
    }
    return buttonNames[key]
  }

  const ContactText = () => {
    const phone = T2A_SUPPORT_NUMBER.toString().replace(' ', '')
    const callHREF = `tel:${phone}`
    const whatsAppMessage = encodeURIComponent('Hello, I encountered a payment issue. Can you assist me with resolving it?')
    const whatsAppHREF = `https://wa.me/${phone}?text=${whatsAppMessage}`

    return <>
      Please contact our Customer Support{' '}
      <a className="a-link a-link-icon" href={callHREF}>
        <CallRounded /> {T2A_SUPPORT_NUMBER}
      </a>{' '}
      or{' '}
      <a className="a-link a-link-icon a-link-whatsapp" href={whatsAppHREF}>
        <WhatsApp /> Chat
      </a>{' '}
      between <span style={{ whiteSpace: 'nowrap' }}>7 AM to 8 PM</span> for further assistance.
    </>
  }

  const DealPurchaseSuccessContent = () => {
    let recharge = { amount: 1, minutes: 5 }
    if (rechargeRedirect.getItem('recharge')) {
      recharge = { ...recharge, ...rechargeRedirect.getItem('recharge') }
    }
    if (isLastOrderIsDealPurchase()) {
      recharge = isLastOrderIsDealPurchase()
    }

    let consult = 'chat'
    if (rechargeRedirect.getItem('type')) {
      consult = rechargeRedirect.getItem('type')
    }

    const TextContent = () => {
      if (isDealPurchaseVoucherEnabled()) {
        return <>Your payment is successful</>
      }

      return (
        <>
          Your recharge for ₹{recharge.amount} is successful<br />
          Enjoy your {recharge.minutes} minute {consult}
        </>
      )
    }

    return (
      <>
        <CheckIcon className={clsx(classes.successIcon, classes.successNewIcon)} />
        <div style={{ fontSize: "1.2rem", color: 'var(--clr-green)' }}>Success!</div>
        <div className={classes.errorPara} style={{ textAlign: "center", marginBottom: 0, lineHeight: '1.5rem' }}>
          <TextContent />
        </div>
        <div className={classes.errorLabel}>Please do not press back button,
          click {getButtonName()} below</div>

        <Button variant="contained" className={classes.successButton} onClick={onButton}>
          {getButtonName()}
        </Button>
      </>
    )
  }

  const DealPurchaseFailedContent = () => {
    const validateDealPurchase = () => {
      return DealPurchase.isPaymentRequest()
    }

    return (
      <></>
    )

    return (
      validateDealPurchase ?
        <>
          <mark>Deal purchase {props?.status ? props.status.replace('_', ' ') : 'failed'}</mark>
        </>
        :
        <></>
    )
  }

  const isLastOrderIsDealPurchase = () => {
    let data = localStorage.getItem('last_order_details')
    if (data !== null) {
      data = JSON.parse(data)
      if (data?.item?.deal_type === 'FREE_CONSULT_MINUTES') {
        return data?.item
      }
    }
    return false
  }

  const InnerComponent = () => {
    // console.log({ props })

    return (
      <>
        <div
          className={clsx(
            classes.paymentWrap,
            props?.className ? props.className : ''
          )}
        >
          {/* <CloseIcon className={classes.closeModal} onClick={onButton} /> */}
          <div className={classes.paperCenter}>
            {props.status === "success" ? (
              <>
                {
                  DealPurchase.isPaymentRequest() || isLastOrderIsDealPurchase() ?
                    <DealPurchaseSuccessContent />
                    :
                    <>
                      <CheckIcon className={classes.successIcon} />
                      <div className={classes.errorPara} style={{ textAlign: "center" }}>
                        <div style={{ color: "#ee600d" }}>
                          <big style={{ fontSize: "2.4rem" }}>{props.rechargeValue}</big>
                          <small style={{ fontSize: "1.2rem" }}> points</small>
                        </div>
                        Credited to your wallet successfully.
                      </div>
                      <div className={classes.errorLabel}>Please do not press back button,
                        click {getButtonName()} below</div>

                      <Button variant="contained" className={classes.successButton} onClick={onButton}>
                        {getButtonName()}
                      </Button>
                    </>
                }

              </>
            ) : props.status === "failed" && props.orderId.length > 0 ? (
              <>
                <CloseIcon className={clsx(classes.errorIcon, 'error-icon')} />
                <div className={clsx(classes.errorText, 'error-text')}>Transaction Failed</div>
                <DealPurchaseFailedContent />
                <div className={clsx(classes.errorPara, 'error-para')}>
                  <div>
                    We are sorry! The last payment was unsuccessful(<b>#Order ID</b>:{props.orderId}). If your account
                    has been debited, the amount will be credited to your wallet once we receive a confirmation from
                    your bank.
                  </div>
                  <br />
                  <br />
                  <div style={{ fontSize: "0.8rem", color: "#666666" }}>
                    Note: Bank confirmation usually takes upto 24 hours.
                    <br />
                    <br />
                    <ContactText />
                  </div>
                </div>

                {props?.actionButton ?
                  props.actionButton :
                  <Button variant="contained" className={classes.errorButton} onClick={onButton}>
                    Okay
                  </Button>
                }
              </>
            ) : (
              <>
                <CloseIcon className={clsx(classes.errorIcon, 'error-icon')} />
                <div className={clsx(classes.errorText, 'error-text')}>Transaction {props?.status ? props.status.replace('_', ' ') : 'failed'}</div>
                <DealPurchaseFailedContent />
                <div className={clsx(classes.errorPara, 'error-para')}>
                  <div>
                    We are sorry! The last payment was unsuccessful. If your account has been debited, the amount will
                    be credited to your wallet once we receive a confirmation from your bank.
                  </div>
                  <br />
                  <br />
                  <div style={{ fontSize: "0.8rem", color: "#666666" }}>
                    Note: Bank confirmation usually takes upto 24 hours.
                    <br />
                    <br />
                    <ContactText />
                  </div>
                </div>
                {props?.actionButton ?
                  props.actionButton :
                  <Button variant="contained" className={classes.errorButton} onClick={onButton}>
                    Okay
                  </Button>
                }
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  const afterVerify = () => {
    // Check the voucher status
    const { voucherData: voucher } = rechargeRedirect.getUpdated()
    console.log('afterVerify:voucher', voucher)

    // Reset wallet payment gateway
    WalletUtils.setType(WalletUtils.getDefaultType())

    if (voucher?.status) {
      if (voucher.status === 0) {
        gotoListing();
      } else {
        // Show the dialog
        setVoucherDialog(d => {
          return {
            ...d,
            open: true,
            voucherStatus: voucher.status,
          }
        })
      }
    } else {
      // Show the dialog when the voucher status is null
      setVoucherDialog(d => {
        return {
          ...d,
          open: true,
          voucherStatus: 3, // Assume the user is (status code = 3) CA free purchase
        }
      })
    }

  }
  const triggerActiveActionBtn = () => {
    // Action btn -> click -> 
    const refData = rechargeRedirect.getUpdated()
    BrowserUtils.devAlert('refData:' + JSON.stringify(refData))
    if (refData.astrologerID) {
      refData.birthDetailsRequired = true
      rechargeRedirect.setData(refData);
      setVoucherDialog(d => { return { ...d, open: false } })
      setAstrologerConsultLoading({
        astrologer: refData,
        actionType: refData.type,
        open: true,
        onComplete: () => {
          continueChatCall(refData)
        },
        modalId: refData.astrologerID
      })
    } else {
      gotoListing()
    }
  }

  const continueChatCall = async (refData) => {
    let consultId = 0;
    let freeMinutes = 0;
    const astroInfo = await getAstrologerInfo(refData);
    BrowserUtils.devAlert('continueChatCall: ' + JSON.stringify(refData))
    console.log('continueChatCall: astroInfo for event', astroInfo)

    if (refData.type === 'call') {
      axios()
        .post("/consultations/call", {
          id: refData.astrologerID,
          mode: "audio",
          proceed_short_call: true,
        })
        .then((res) => {
          if (res.data.success) {
            const secondsSinceEpoch = Math.floor(Date.now() / 1000);
            consultId = res.data.data.id;
            freeMinutes = parseInt(astroInfo?.free_call_minutes || '0');

            FA_localStorage(
              "lastActiveConsultation",
              JSON.stringify({
                id: res.data.data.id,
                key: md5(res.data.data.id.toString()),
                token: res.data.data.token,
                status_token: res.data.data.status_token,
                astrologer: refData.astrologerName,
                astrologerUrl: refData.astrologerProfileURL,
              })
            );

            localStorage.setItem(
              "progress_call",
              "1|" +
              (parseInt(secondsSinceEpoch) + parseInt(res.data.data.time)) +
              "|" +
              res.data.data.id +
              "?token=" +
              res.data.data.token +
              "&stkn=" +
              res.data.data.status_token +
              "&prl=%2Fastrologer%2F" +
              refData.astrologerProfileURL +
              "|" +
              refData.astrologerName
            );

            window.location.href =
              "/astrologer-connect/" +
              res.data.data.id +
              "?token=" +
              res.data.data.token +
              "&stkn=" +
              res.data.data.status_token +
              "&prl=%2Fastrologer%2F" +
              refData.astrologerProfileURL;
          }
        })
        .catch((err) => {
        })

    } else {
      const chatInfo = await initChat(refData.astrologerID);
      BrowserUtils.devAlert('chatInfo: ' + JSON.stringify(chatInfo))
      BrowserUtils.devAlert('refData: ' + JSON.stringify(refData))
      if (refData.astrologerID && chatInfo && chatInfo.success === true) {
        setChatWindowData({
          astrologer: refData.astrologerID,
          time: Date.now(),
          chatID: chatInfo.chatID,
          status: chatInfo.status,
        })
        BrowserUtils.devAlert('setChatWindowData: ' + JSON.stringify({
          astrologer: refData.astrologerID,
          time: Date.now(),
          chatID: chatInfo.chatID,
          status: chatInfo.status,
        }))
      }
      consultId = chatInfo?.receiver?.consultationId || 0;
      if (consultId) {
        notifyChat(consultId)
      }
      freeMinutes = parseInt(astroInfo?.free_chat_minutes || '0')
      updateUserConsulationId(chatInfo.chatID, consultId)
    }
  }

  const getAstrologerInfo = async (data) => {
    return new Promise((resolve, reject) => {
      axios()
        .post(`/astrologers/${data.astrologerProfileURL}`)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data) {
              resolve(res.data.data)
            } else {
              reject(res)
            }
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const updateUserConsulationId = (chatID, newConsultationId) => {
    Object.entries(localStorage).forEach(([key, value]) => {
      if (key.length > 9 && key.substr(0, 9) === 'cnslt_id_') {
        localStorage.removeItem(key)
      }
    })
    if (newConsultationId) {
      localStorage.setItem('cnslt_id_' + chatID, newConsultationId);
    }
  }

  const gotoListing = () => {
    window.location.href = `/app-home`
  }

  return (
    <div>

      {isDealPurchaseVoucherEnabled()
        && paymentStatus
        && paymentStatus.toString().toLowerCase() === 'success'
        && showContinueLoginButton ?
        <div className={classes.dealDefaultVoucherScreen}>
          <div className={classes.dealDefaultVoucherScreenHolder}>
            <Button variant="contained" className={classes.successButton} onClick={onButton}>
              Continue to Login
            </Button>
          </div>
        </div>
        : <></>
      }

      {
        props.contentOnly ?
          <InnerComponent />
          :
          <Dialog
            open={isOpen}
            className={clsx(
              classes.dialogContainer,
            )}
          >
            <InnerComponent />
          </Dialog>
      }

      <VerifyNumber
        open={verifyNumber.open}
        handleClose={() => {
          setVerifyNumber(v => { return { ...v, open: false } });
          // gotoListing();
        }}
        afterVerify={afterVerify}
        overlayClose="disabled"
      />

      <DealVoucherPurchaseAfterLoginDialog
        open={voucherDialog.open}
        handleClose={() => setVoucherDialog(false)}
        triggerActiveActionBtn={triggerActiveActionBtn}
        voucherStatus={voucherDialog.voucherStatus}
      />

      {astrologerConsultLoading?.astrologer && <AstrologerLoadingModal opt={astrologerConsultLoading} />}

      <div className="astrologerInfo visibleInCurrentTab">
        <div className="btn-action" onClick={() => setVerifyNumber(v => { return { ...v, open: true } })} ref={verifyRef}></div>
      </div>

      <ChatWindowStateProvider value={chatWindowData}>
        <ChatWindowStateSetterProvider value={setChatWindowData}>
          <ChatNotificationTrigger setChatWindowData={setChatWindowData} setLoading={setLoading} openProfile={e => { }} handleUnLogged={e => { }} />
          {(isChatEnabled() && chatWindowData?.chatID) &&
            <div id="new-chat">
              <Conversations showAstrologerProfile={e => { }} setIsConvLoading={e => { }} />
            </div>
          }
        </ChatWindowStateSetterProvider>
      </ChatWindowStateProvider>
    </div>
  );
}
