import { Avatar, CircularProgress, Dialog } from "@material-ui/core";
import React, { useContext, useEffect, useState, useRef } from "react";
import SimpleChat from "./chat-core";
import Chat from "./Chat";
// import conversation from "./conversation.json";
import "./chat.css";
import { displayDateTime, listConversations, getChatUserID, getChatServerConfig, prepareSingleMessage, sendMessage, JSONMessage } from "./ChatUtls";
import { ChatWindowStateContext, ChatWindowStateSetterContext } from "./ChatWindowContext";
import AstrologerUtils from "../AstrologerUtils";
import { isMobile } from "react-device-detect";
import BrowserUtils from "../UAScripts";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import EndMessages from "./RandomMessages";
import LowBalanceEndMessages from "./LowBalanceRandomMessages";

const MessageType = {
  MINE: 1,
  OTHER: 2,
};

function Conversations(props) {
  const ChatWindowData = useContext(ChatWindowStateContext);
  const [conversations, setConversations] = useState([]);
  const [refreshTime, setRefreshTimeline] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [sender, setSender] = useState(null);
  const [chatWindowOpen, setChatWindowOpen] = useState(false);
  const [chatClient, setChatClient] = useState(false);
  const [messages, setMessages] = useState([]);
  const showAstrologerProfile = props.showAstrologerProfile;
  const [latestMessage, setLatestMessage] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [chatID, setChatID] = useState(null);
  const [activeChatStatus, setActiveChatStatus] = useState("not-set");
  const [isOtherUserTyping, setIsOtherUserTyping] = useState(false);
  const [offlineMessages, setOfflineMessages] = useState([]);
  const [initiatedAt, setInitiatedAt] = useState(false);
  const [enableInactivityTimer, setEnableInactivityTimer] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [activeConversation, setActiveConversation] = useState(undefined);
  const [currentServerTime, setCurrentServerTime] = useState(false);
  const [notificationSoundFile, setNotificationSoundFile] = useState(
    "https://assets.findastro.com/chat/fa_notification_sametab.mp3"
  );
  const [isSoundEnabled, setIsSoundEnabled] = useState(true);
  const token = localStorage.getItem("btkn");
  const { host, port } = getChatServerConfig();
  const mounted = useRef(false);
  const history = useHistory();
  const setChatWindowData = useContext(ChatWindowStateSetterContext);
  const [inActiveUser, setInactiveUser] = useState('');
  const [isLowBalance, setIsLowBalance] = useState(false);
  const [userNotResponded, setUserNotResponded] = useState(false);
  const [randomEndMessage, setRandomEndMessage] = useState(false);
  const [astrologerGreeting, setAstrologerGreeting] = useState({});
  const [chatEndSummary, setChatEndSummary] = useState({});

  useEffect(() => {
    mounted.current = true;
    // console.log("mounted");

    return () => {
      mounted.current = false;
      // console.log("unmounted");
    };
  }, []);


  async function fetchMyAPI() {
    //setLoading(true);
    const user_data = await getChatUserID();
    console.log({ user_data })
    setSender(user_data.userId);
    localStorage.setItem('userid', user_data.userId);
    //setLoading(false);
  }

  useEffect(() => {
    fetchMyAPI()
  }, []);

  // useEffect(() => {
  //   if (props.freeMessageStatus === 'reconnect') {
  //     fetchMyAPI()
  //   }
  // }, [props.freeMessageStatus]);

  useEffect(() => {
    // copy use effect of fa-chat client.
    if (sender === undefined || sender === null) {
      return true;
    }
    // console.log("senderID", sender);
    const cClient = new SimpleChat({
      host,
      port,
      userId: sender,
      accessToken: token + ",fa,user",
    });
    cClient.on("connected", () => {
      // console.log("connected");
      setIsConnected(true);
    });
    cClient.on("disconnected", () => {
      // console.log("disconnected");
      setIsConnected(false);
    });
    cClient.on("reconnected", () => {
      // console.log("reconnected");
      setIsConnected(true);
    });
    cClient.start();
    cClient.on("message", (chatMessage, ack) => {
      ack();
      setLatestMessage(chatMessage);
    });
    cClient.on("error", (err) => {
      // console.log("message-offline", offlineMessages);
      // console.log("error", err);
    });
    cClient.on("typing", (chatMessage, ack) => {
      handleOtherUserTyping();
    });
    // console.log('cClient: ', cClient);
    setChatClient(cClient);
  }, [sender]);

  const handleOtherUserTyping = () => {
    setIsOtherUserTyping(true);
    setTimeout(function () {
      setIsOtherUserTyping(false);
    }, 3000);
  };

  useEffect(() => {
    if (latestMessage !== false) {
      onMessageReceive(latestMessage);
    }
  }, [latestMessage]);

  const onMessageReceive = (chatMessage) => {
    // console.log('chatMessage:', chatMessage)
    chatMessage = JSONMessage.parse(chatMessage)

    if (chatMessage?.body?.text?.inactive_user) {
      setInactiveUser(chatMessage.body.text.inactive_user)
    }

    if (chatMessage?.body?.text?.action === 'chat-low-balance') {
      setIsLowBalance(true)
    }

    if (chatMessage?.body?.text?.action === 'chat-end' &&
      chatMessage.body.text.text.includes('because you did not respond')) {
      // console.log('userNotResponded:', userNotResponded)
      setUserNotResponded(true)
    }

    // console.log("message-received");
    if (chatMessage.from === "bot") {
      if (chatMessage.body.text.visibility !== "none") {
        playSound();
        setMessages([...messages, chatMessage]);
      } else {
        // console.log("invisible message");
      }
    } else {
      setMessages([...messages, chatMessage]);
      playSound();
    }
    if (chatMessage.from === "bot") {
      if (chatMessage.body.text !== undefined && chatMessage.body.text.action === "chat-accept-init") {
        setInitiatedAt(chatMessage.body.text.time_utc);
        setCurrentServerTime(chatMessage.body.text.time_utc);
      }
      notifyEvents(chatMessage.body.text.action, chatMessage);
    }
  };

  const playSound = () => {
    if (isSoundEnabled) {
      setIsSoundEnabled(false);
      let audPromise = new Audio(notificationSoundFile).play();
      if (audPromise !== undefined) {
        audPromise.then(function () {
          // Automatic playback started!
        }).catch(function (error) {
          // Automatic playback failed.
          console.log(error)
        });
      }
      setTimeout(function () {
        setIsSoundEnabled(true);
      }, 500);
    }
  };

  useEffect(() => {
    window.document.addEventListener("visibilitychange", visibilityListener);
  }, []);

  const visibilityListener = () => {
    switch (document.visibilityState) {
      case "hidden":
        setNotificationSoundFile("https://assets.findastro.com/chat/fa_notification_othertab.mp3");
        break;
      case "visible":
        setNotificationSoundFile("https://assets.findastro.com/chat/fa_notification_sametab.mp3");
        break;
      default:
        console.log("tab error");
    }
  };

  useEffect(() => {
    // console.log("conv:ChatWindowData", ChatWindowData);
    if (ChatWindowData.chatID) {
      openConversation(ChatWindowData.chatID, ChatWindowData.status, ChatWindowData.astrologer);
    }
  }, [ChatWindowData.time]);

  const findActiveConv = (item) => {
    return item.latest_chat.consultation.status !== "completed" && item.latest_chat.consultation.status !== "failed" && item.latest_chat.consultation.status !== "refunded";
  };

  const updateConversations = async () => {
    //setLoading(true);
    const msgs = await listConversations(chatClient);
    // console.log("set cov", msgs.messages);
    setConversations(msgs.messages.data);
    const activeConv = msgs.messages.data.find(findActiveConv);
    // console.log("actv-conv", activeConv);
    setActiveConversation(activeConv);
    //setLoading(false);
    props.setIsConvLoading(false);
  };

  const refreshChat = () => {
    setTimeout(function () {
      // console.log("refreshtime");
      setRefreshTimeline(Date.now());
    }, 1000);
  };

  useEffect(() => {
    // chatClient && logout(chatClient);
    if (sender) {
      setRefreshTimeline(Date.now());
    }
  }, [sender]);

  // useEffect(() => {
  //   if (props.freeMessageStatus === 'reconnect') {

  //     async function fetchMyAPI() {
  //       //setLoading(true);
  //       const user_data = await getChatUserID();
  //       setSender(user_data.userId);
  //       localStorage.setItem('userid', user_data.userId);
  //       //setLoading(false);
  //     }
  //     fetchMyAPI();

  //     updateConversations();
  //   }
  // }, [props.freeMessageStatus]);

  useEffect(() => {
    if (!chatWindowOpen && refreshTime) {
      updateConversations();
    }
  }, [refreshTime]);

  useEffect(() => {
    // console.log("listconversations", conversations);
  }, [conversations]);

  const openConversation = (to, status, receiver) => {
    // console.log(to);
    setChatID(to);
    // console.log("status", status);
    setActiveChatStatus(status);
    setReceiver(receiver);
    setChatWindowOpen(true);
    window.location.hash = "#backtochat";
  };

  const closeConversation = () => {
    if (BrowserUtils.isNewUI() && false) {
      disconnectChat();
      history.push('/')
    } else {
      window.location.href = "/";
    }
    // updateConversations();
    // setChatID(false);
    // setChatWindowOpen(false);
  };
  const handleTriggerNotificationBackground = (e) => {
    // console.log("user-offline");
    disconnectChat();
  };
  const disconnectChat = () => {
    if (chatClient && isConnected) {
      // console.log("debug-disconnect", chatClient);
      chatClient.disconnect(true);
    } else {
      // console.log("debug-disconnect-fail", chatClient, isConnected);
    }
  };
  const handleTriggerNotificationUserBack = (e) => {
    // console.log("user-back");
    reconnectChat();
  };
  const reconnectChat = () => {
    if (chatClient && !isConnected) {
      // console.log("debug-reconnect", chatClient);
      // chatClient.reconnect(true);
      chatClient.start();
    } else {
      // console.log("debug-reconnect-fail", chatClient, isConnected);
    }
  };

  const sendRandomEndMessage = () => {
    const senderAstrologer = typeof ChatWindowData.astrologer !== 'undefined' ? ChatWindowData.astrologer : receiver; //activeConversation.astrologer.id
    const randomIndex = Math.floor(Math.random() * EndMessages.length)
    const message = EndMessages[randomIndex]
    if (senderAstrologer) {
      setTimeout(function () {
        setRandomEndMessage(prepareSingleMessage(message, `astrologer-${senderAstrologer}`, chatID))
      }, 3000)
    } else {
      console.log("Endchat: astrologer id is missing - " + chatID)
    }
  }

  const sendLowBalanceAstrologerMsg = () => {
    const senderAstrologer = typeof ChatWindowData.astrologer !== 'undefined' ? ChatWindowData.astrologer : receiver; //activeConversation.astrologer.id
    //const randomIndex = Math.floor(Math.random() * LowBalanceEndMessages.length)
    const message = LowBalanceEndMessages[0]
    if (senderAstrologer) {
      setTimeout(function () {
        setRandomEndMessage(prepareSingleMessage(message, `astrologer-${senderAstrologer}`, chatID))
      }, 4000)
    } else {
      console.log("Lowbalance: astrologer id is missing - " + chatID)
    }
  }

  useEffect(() => {
    if (randomEndMessage !== false && BrowserUtils.isNewUI()) {
      setMessages([...messages, randomEndMessage]);
      // const sentMessage = await sendMessage(chatClient, chatID, message);
      // console.log('sendRandomEndMessage:', randomEndMessage)
    }
  }, [randomEndMessage])

  const clearRandomMsgStates = () => {
    setInactiveUser('')
    setIsLowBalance(false)
    setUserNotResponded(false)
  }

  const notifyEvents = (action, chatMessage) => {

    if (props?.freeMessageEvent) {
      props.freeMessageEvent.activeChatStatus(action, chatMessage)
    }

    // console.log('notifyEvents:', action)
    if (action === "chat-pending") {
      // console.log("Chat pending");
      setActiveChatStatus("pending");
    }
    if (action === "chat-reject") {
      // console.log("Chat Request Rejected");
      setActiveChatStatus("rejected");
    }
    if (action === "chat-cancel") {
      //console.log("Chat Request cancelled");
      setActiveChatStatus("cancelled");
    }
    if (action === "chat-end") {
      // console.log("Chat Request cancelled");
      // console.log('userNotResponded:', userNotResponded)
      // console.log('chatMessage.body.text.text:', chatMessage.body.text.text)
      if (userNotResponded || chatMessage.body.text.text.includes('because you did not respond')) {
        sendRandomEndMessage();
      }

      if (isLowBalance) {
        sendLowBalanceAstrologerMsg()
      }
      setActiveChatStatus("end");
      clearRandomMsgStates();
    }
    if (action === "chat-auto-end") {
      // console.log("Chat Request cancelled");
      setActiveChatStatus("auto-end");
      clearRandomMsgStates();
    }
    if (action === "chat-end-manual") {
      // console.log("Chat End manual");
      setActiveChatStatus("completed");
    }
    if (action === "chat-end-auto") {
      // console.log("Chat End auto");
      if (inActiveUser === 'user' || isLowBalance) {
        sendRandomEndMessage()
      }
      if (isLowBalance) {
        sendLowBalanceAstrologerMsg()
      }
      setActiveChatStatus("completed");
    }
    if (action === "chat-accept") {
      // console.log('sender:', sender, ChatWindowData.astrologer)
      // console.log("Chat accepted");
      setActiveChatStatus("accepted");
    }
    if (action === "chat-accept-init") {
      // console.log("Chat initated");
      setActiveChatStatus("initiated");
      setEnableInactivityTimer(false);
    }
    if (action === "chat-end-warning") {
      // console.log("Chat end warning");
      setEnableInactivityTimer(true);
      window.chatSessionRefreshTime = Date.now();
    }
    if (action === "chat-end-warning-stop") {
      // console.log("Chat end warning stop");
      setEnableInactivityTimer(false);
    }
    if (action === "astrologer-greeting") {
      setAstrologerGreeting(chatMessage)
    }
    if (action === "chat-end-summary") {
      setChatEndSummary(chatMessage)
    }
  };

  const transformMessages = (messages) => {
    return messages.map(msg => JSONMessage.parse(msg))
  }

  return (
    <>
      <div style={{ position: "fixed", bottom: 0, display: "none" }}>
        <input type="text" id="triggerNotificationUserBack" onChange={handleTriggerNotificationUserBack} />
        <input type="text" id="triggerNotificationBackground" onChange={handleTriggerNotificationBackground} />
      </div>
      {!chatWindowOpen && activeConversation !== undefined && (
        <>
          <div
            className={
              isMobile
                ? "active-chat-ribbon active-consultation"
                : "active-chat-ribbon active-consultation active-chat-ribbon-desktop"
            }
            id="chat-active-consultation"
            data-astrologer-id={activeConversation.latest_chat.astrologer_id}
            onClick={() =>
              openConversation(
                activeConversation.latest_chat.chat_id,
                activeConversation.latest_chat.consultation.status,
                activeConversation.latest_chat.astrologer_id
              )
            }>
            <div className="active-chat-astrologer-pic">
              <Avatar
                alt={activeConversation.astrologer.name}
                className="imageAvatar"
                src={AstrologerUtils.createProfileImageURI(activeConversation.astrologer.id) /* msg.owner.id */}
              />
            </div>
            <div className="active-chat-status">{`Continue chat with ${activeConversation.astrologer.name}`}</div>
          </div>
        </>
      )}
      {isLoading ? (
        <div className="circle-loading circle-loading-20px" style={{ position: "fixed", top: 0, left: 0, zIndex: 9999 }}>
          <CircularProgress />
        </div>
      ) : (
        <></>
      )}
      {!chatWindowOpen ? (
        <div className="chat-main-window">
          {conversations && conversations.length > 0 ? (
            conversations.map((msg) => {
              let msgStatus = "sent";
              // if (msg.delivered === true) {
              //   msgStatus = "delivered";
              // } else if (msg.read === true) {
              //   msgStatus = "read";
              // } else {
              //   msgStatus = "sent";
              // }
              return (
                msg.astrologer && (
                  <div
                    key={msg.astrologer.id}
                    className={
                      "chat-latest-msg-wrapper " +
                      /*msg.type !== MessageType.MINE && msg.read === false */ (true ? "unread" : "read")
                    }
                    onClick={() =>
                      openConversation(
                        msg.latest_chat.chat_id,
                        msg.latest_chat.consultation.status,
                        msg.latest_chat.astrologer_id
                      )
                    }>
                    <div className="chat-avatar">
                      <Avatar
                        alt={msg.astrologer.name}
                        className="imageAvatar"
                        src={msg.astrologer.deleted_at ? null : AstrologerUtils.createProfileImageURI(msg.astrologer.id) /* msg.owner.id */}
                      // onClick={(e) => {
                      //   if (msg.astrologer.profile_url) {
                      //     e.stopPropagation();
                      //     showAstrologerProfile(msg.astrologer);
                      //   }
                      // }}
                      />
                    </div>
                    <div className="chat-owner-msg-wrapper">
                      <div className="chat-owner">
                        {msg.astrologer.name} {isOtherUserTyping ? "..." : ""}
                      </div>
                      <div className="chat-latest-msg">
                        <div className="chat-latest-msg-text">
                          <span className={`chat-status-${msgStatus}`}>
                            {/* {msgStatusDisplay[msgStatus]} */}
                            &#10003;
                          </span>
                          {msg.latest_chat.created_from !== 0
                            ? msg.latest_chat.latest_message
                              ? msg.latest_chat.latest_message
                              : "No message to be displayed"
                            : msg.latest_chat.latest_message
                              ? JSON.parse(msg.latest_chat.latest_message).text
                              : "No message to be displayed"}
                        </div>
                        <div className="chat-latest-msg-time">{displayDateTime(msg.latest_chat.updated_at)}</div>
                      </div>
                    </div>
                  </div>
                )
              );
            })
          ) : (
            <>
              {!isLoading && (
                <div id="chat-conv-empty">
                  You have no chat consultations. Please go to astrologers tab to start chat a consultation.
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <Dialog open={true} fullScreen>
          <div
            className={[
              isMobile ? `chat-window` : `chat-window chat-window-except-mobile`,
              BrowserUtils.isNewUI() ? 'new-ui-chat-window' : ''
            ].join(' ').trim()}
          >
            {/* <div className="chat-window-header">
              <button onClick={closeConversation}>Back</button>
            </div> */}
            {chatClient && <Chat
              chatClient={chatClient}
              chatID={chatID}
              sender={sender}
              receiver={receiver}
              isOtherUserTyping={isOtherUserTyping}
              setMessages={setMessages}
              messages={messages}
              closeConversation={closeConversation}
              notifyEvents={notifyEvents}
              activeChatStatus={activeChatStatus}
              setActiveChatStatus={setActiveChatStatus}
              offlineMessages={offlineMessages}
              setOfflineMessages={setOfflineMessages}
              setInitiatedAt={setInitiatedAt}
              initiatedAt={initiatedAt}
              enableInactivityTimer={enableInactivityTimer}
              setLoading={setLoading}
              setCurrentServerTime={setCurrentServerTime}
              currentServerTime={currentServerTime}
              astrologerGreeting={astrologerGreeting}
              chatEndSummary={chatEndSummary}
              randomEndMessage={randomEndMessage}
              freeMessages={props?.freeMessages ? props.freeMessages : []}
              freeMessageStatus={props?.freeMessageStatus ? props.freeMessageStatus : ''}
              freeMessageEvent={props?.freeMessageEvent ? props.freeMessageEvent : ''}
            />}
          </div>
        </Dialog>
      )}
    </>
  );
}

export default Conversations;
