import React, { Component, useEffect, useState } from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import "./MyAccount.css";
import walletData from "../mock/MyAccountData.mock";
import HelmetComponent from "../../../common/HelmetComponent.component";
import ModalComponent from "./WalletModal.component";
import Snackbar from "../../../common/SnackBarMessage";


import QueryString from "../../../common/util/QueryString";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import { useRechargeRedirect as getRechargeRedirect } from "../../user/astrologer/components/RechargeLinkRedirect";
import T2AEventTrack from "../../../common/T2AEventTrack";
import clsx from "clsx";
import RechargeOptionItem from "../../user/astrologer/components/RechargeOptionItem";
import paymentImage from "../../../../src/common/secure-payment.png"
import { Backdrop, Button, CircularProgress, IconButton, makeStyles } from "@material-ui/core";
import ReviewsPanel from "../../user/astrologer/components/ReviewsPanel";
import { Skeleton } from "@material-ui/lab";
import { ChevronLeft } from "@material-ui/icons";
import AstrologerReservedMessage from "../../user/astrologer/components/AstrologerReservedMessage";
import { MIN_BALANCE_MINS } from "../../../app.config";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import PaymentDialog from "./PaymentDialog";
import PaymentFailedDialog from "./PaymentFailedDialog";
import Transaction from "../Transaction";
import DealPurchase from "../../feature/deal-purchase/components/DealPurchase";
import DealPurchasePaymentResponseDialog from "../../feature/deal-purchase/components/DealPurchasePaymentResponseDialog";
import { getURLParams } from "../../user/astrologer/components/chat/ChatUtls";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    // color: 'var(--clr-secondary)',
    color: '#ee600d',
  },
}));

const WalletPage = (props) => {
  const classes = useStyles();
  const history = useHistory()
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "Error occurred, Please try again",
  });
  const [walletPoints, setWalletPoints] = useState(localStorage.getItem("walletBalance") || "0");
  const [rechargeValue, setRechargeValue] = useState(0);
  const [amount, setAmount] = useState(0);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isRechargeOptionsLoading, setIsRechargeOptionsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [tId, setTId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [points, setPoints] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [oldStatus, setOldStatus] = useState("failed");
  const [modalOpen, setModalOpen] = useState(false);
  const [walletOptions, setWalletOptions] = useState([]);
  const [instantPaymentMethod, setInstantPaymentMethod] = useState(false);
  const [reviewsParameter, setReviewsParameter] = useState({});
  const [highlightPlan, setHighlightPlan] = useState(499);
  const { params_id } = useParams();
  const rechargeRedirect = getRechargeRedirect();
  const [paymentDialog, setPaymentDialog] = useState({
    open: false,
    paymentURL: '',
    fullScreen: false,
  });
  const [paymentFailedDialog, setPaymentFailedDialog] = useState({
    open: false,
    paymentURL: '',
    fullScreen: false,
  });
  const eventsPayload = {
    event: {
      attributes: {
        'Product': 'COT',
        'User ID': BrowserUtils.CurrentUserInfo().id,
        'User Name': BrowserUtils.CurrentUserInfo().name,
      }
    },
    extra: {
      'User ID': BrowserUtils.CurrentUserInfo().id,
      'User Name': BrowserUtils.CurrentUserInfo().name,
    }
  };
  const [transactionOpen, setTransactionOpen] = useState(false);

  const params = new URLSearchParams(window.location.search)
  const status = params.get('status')
  const [blankBackgroundScreen, setBlankBackgroundScreen] = useState(false)
  const [dealErrorOrder, setDealErrorOrder] = useState({
    open: false,
    type: 'chat',
    response: {},
  })

  useEffect(() => {
    // console.log({ defaultHighlightPlan: highlightPlan, rrHighlight: rechargeRedirect.getItem('highlight') })
    if (rechargeRedirect.getItem('highlight')) {
      setHighlightPlan(rechargeRedirect.getItem('highlight'))
    }

    window.addEventListener('popstate', handlePopState);

    const reviewsParameter = {}
    if (localStorage.getItem('userProfileId')) {
      reviewsParameter.user_id = localStorage.getItem('userProfileId')
    } else if (localStorage.getItem('userid')) {
      reviewsParameter.user_id = localStorage.getItem('userid').split('-')[1]
    }

    // Astrologer ID from the recharge request
    const recharge_astrologer = rechargeRedirect.getItem('astrologerID')

    // If a last consulted astrologer ID is available in the localStorage
    if (localStorage.getItem('last_consulted_astrologer')) {
      const last_consulted_astrologer = parseInt(localStorage.getItem('last_consulted_astrologer'))

      let param = {
        num_of_reviews: 60,
        rating_from: 4,
        min_char_limit: 15,
        sort_by: 'shuffle',
        astrologer: [last_consulted_astrologer]
      }
      // Check if the astrologer has any reviews
      axios()
        .post("/getReviews", param)
        .then((res) => {
          if (res.data.status === 'success' && res.data.reviews && res.data.reviews.length) {
            reviewsParameter.astrologer = [last_consulted_astrologer]
          } else if (recharge_astrologer) {
            reviewsParameter.astrologer = [recharge_astrologer]
          }
          reviewsParameter.extra = {
            limit_comments: 3,
            random: false
          }
          setReviewsParameter(reviewsParameter)
        })
        .catch((err) => {
          console.warn(err)
        })
    } else {
      reviewsParameter.extra = {
        limit_comments: 3,
        random: false,
        astrologer: [recharge_astrologer]
      }
      setReviewsParameter(reviewsParameter)
    }

    let instantPay = 0;
    if (isInstantPaymentRequest()) {
      setInstantPaymentMethod(true)
      instantPay = 1;
    }

    // Handle status after payment non-success
    if (status !== null) {

      // console.log('wc:/no-failed-popup', window.location.hash.includes('/no-failed-popup'))
      if (!window.location.hash.includes('/no-failed-popup')) {
        setIsLoading(false)
        setPaymentFailedDialog({
          open: true,
          status
        })
        setInstantPaymentMethod(false)
      } else {
        setIsLoading(false)
        setInstantPaymentMethod(false)
        setPaymentFailedDialog(dialog => { return { ...dialog, open: false } });
        setBlankBackgroundScreen(false)
        setTimeout(() => { setBlankBackgroundScreen(false) }, 1000)
      }
    }

    if (window.location.pathname === "/user-wallet"
      || window.location.pathname === "/my-wallet"
      || window.location.hash === "#back-button--wallet-recharge") {
      if (!BrowserUtils.isLoggedIn()
      ) {
        // window.location.href = "/login?pathname=" + window.location.pathname;
        window.location.href = '/';
      } else {
        const eData = eventsPayload;
        eData.event.name = 'Screen View Action';
        eData.event.attributes['Screen Name'] = 'My Wallet';
        eData.event.attributes['Wallet Balance'] = localStorage.getItem("walletBalance");
        console.log(eData);
        T2AEventTrack(eData.event, eData.extra);

        if (DealPurchase.isPaymentRequest()) {
          setBlankBackgroundScreen(true)
        }

        axios()
          .post("/my-account")
          .then((res) => {
            if (res.data.success) {
              if (res.data.data.email) {
                localStorage.setItem("name", res.data.data.name);
                localStorage.setItem("email", res.data.data.email);
                localStorage.setItem("phone", res.data.data.phone);
                localStorage.setItem("image", res.data.data.profileUrl);
                localStorage.setItem("walletBalance", res.data.data.walletBalance ? res.data.data.walletBalance : "0");
                setUserData(res.data.data);
                setWalletOptions(res.data.data.walletBalance ? res.data.data.walletBalance : "0");
                setName(res.data.data.name ? res.data.data.name : "");
                setIsLoading(false);
                if (status === null && !getURLParams('all-recharge-options')) {
                  if (isInstantPaymentRequest()) {
                    tryInstantPayment()
                  } else if (DealPurchase.isPaymentRequest()) {
                    tryDealPurchase()
                  }
                }
              } else {
                setWalletOptions(res.data.data.walletBalance ? res.data.data.walletBalance : "0")
                setIsLoading(false);
                if (DealPurchase.isPaymentRequest()) {
                  tryDealPurchase()
                }
              }
            } else {
              showError(res.data.message);
              setIsLoading(false);
              setInstantPaymentMethod(false);
            }
          })
          .catch((err) => {
            sentryError("MY_WALLET_PAGELOAD", err);
            showError("E-WLCA-MA-AA: Error occured, Please try again");
            setIsLoading(false);
            setInstantPaymentMethod(false);
          });

        setIsLoading(true);
        setIsRechargeOptionsLoading(true);
        axios()
          .get("/wallet/rechargeoptions", {
            params: {
              plan_type: ['RECHARGE', 'DEAL'],
              _time: new Date().getTime(),
            }
          })
          .then((res) => {
            if (res.data.success) {
              (async () => {
                let walletOptions = []
                const user = await userUpdates()
                if (user.hasOwnProperty('user_status')) {
                  BrowserUtils.setCachedKey('userStatus', user.user_status);
                }
                const highest_recharge_amount = user?.highest_recharge_amount // similar key `last_recharge_amount`
                // const highest_recharge_amount = 0 // Test case 0/104(instant popup cases)/199/499/999/1999/9999/9999999

                // Previous logic - w/o based on recharge amount
                let previous_logic = false
                if (rechargeRedirect.getItem('refID') === 'home/wallet-top-icon'
                  || rechargeRedirect.getItem('refID') === 'home/recharge-banner'
                  || rechargeRedirect.getItem('refID') === 'deal-purchase-dialog/recharge-now') {
                  previous_logic = true
                }

                // removed COT_22 by adding false condition
                if (false && recharge_astrologer) {
                  let minimumOptionShowCount = 3;
                  if (res.data.options.length < minimumOptionShowCount) {
                    minimumOptionShowCount = res.data.options.length;
                  }

                  const astrologerCharge = rechargeRedirect.getItem('astrologerCharge') || 0;

                  let minAmount = parseInt(astrologerCharge) * MIN_BALANCE_MINS;

                  let balance = parseInt(localStorage.getItem("walletBalance")) || 0;
                  if (minAmount > balance) {
                    minAmount -= balance
                  } else {
                    minAmount = 0;
                  }
                  if (minAmount > 0) {
                    let lastRemoved = -1;
                    res.data.options.map((w, key) => {

                      if (w.points >= minAmount) {
                        walletOptions.push(w);
                      } else {
                        lastRemoved = key;
                      }

                    })

                    // If options less than minimum options show
                    const needOptionCount = minimumOptionShowCount - walletOptions.length
                    if (needOptionCount > 0 && lastRemoved > -1) {
                      // Adding lower options
                      for (let index = 0; index < needOptionCount; index++) {
                        // If options available
                        if (res.data.options[lastRemoved - index]) {
                          walletOptions.push(res.data.options[lastRemoved - index])
                        }
                      }
                    }
                  } else {
                    walletOptions = res.data.options;
                  }
                } else if (highest_recharge_amount !== null && !previous_logic) {

                  // Add next higher options count
                  let addNextHigherOptionCount = 2
                  const minimumOptionShowCount = 3
                  res.data.options.map((w) => {

                    // Amount will be lower or same
                    if (highest_recharge_amount >= w.amount) {
                      walletOptions.push(w)
                    }

                    // Choosing next two higher options
                    else if (addNextHigherOptionCount) {
                      walletOptions.push(w)
                      addNextHigherOptionCount--
                    }

                  })

                  // If options less than minimum options show
                  const needOptionCount = minimumOptionShowCount - walletOptions.length
                  if (needOptionCount) {

                    // Cache the array length
                    const cachedLength = walletOptions.length

                    // Adding higher options
                    for (let index = 0; index < needOptionCount; index++) {

                      // If options available
                      if (res.data.options[cachedLength + index]) {
                        walletOptions.push(res.data.options[cachedLength + index])
                      }
                    }
                  }
                }

                // Existing logic for recharge redirect min max filter
                else {
                  walletOptions = res.data.options.filter(w => {
                    if (rechargeRedirect.getItem('minAmount') && rechargeRedirect.getItem('maxAmount')) {
                      return w.amount > rechargeRedirect.getItem('minAmount') &&
                        w.amount < rechargeRedirect.getItem('maxAmount')
                    }
                    if (rechargeRedirect.getItem('minAmount')) {
                      return w.amount > rechargeRedirect.getItem('minAmount')
                    }
                    if (rechargeRedirect.getItem('maxAmount')) {
                      return w.amount < rechargeRedirect.getItem('maxAmount')
                    }
                    return true
                  })
                }

                // Reversing plans orders
                // walletOptions = walletOptions.reverse()

                setWalletOptions(walletOptions)
                setIsLoading(false);
                setIsRechargeOptionsLoading(false);

              })()
            } else {
              showError("Error occurred, Please try again (Failed to list the recharge options)")
              setIsLoading(false);
              setIsRechargeOptionsLoading(false);
            }
          })
          .catch((err) => {
            sentryError("MY_WALLET_OPTIONS", err);
            showError("E-WLCA-WRO-AA: Error occured, Please try again");
            setIsLoading(false);
            setIsRechargeOptionsLoading(false);
          });
      }
      //window.location.hash = "backtohome";
    }

    console.log('walletPage functional component!')

    return () => {
      setTimeout(() => {
        window.removeEventListener('popstate', handlePopState, false);
      }, 1000)
    }
  }, [])

  const showError = (message) => {
    setSnackBar({
      open: true,
      message: message,
    })
  };

  const goBack = () => {
    if (window.location.hash === `#back-button--wallet-recharge`) {
      history.goBack()
      return
    }

    history.push('/app-home')
    // const params = new URLSearchParams(window.location.search);
    // window.location.href = params.get("pathname") ? params.get("pathname") : "/";
  };

  const closeError = () => {
    setSnackBar(v => {
      return {
        ...v,
        open: false,
      }
    })

  };

  const onSelect = (value, item) => {
    const point = getWalletPoints(value)
    setAmount(value)
    setPoints(point)

    setTimeout(() => {
      onProceed(value, item);
    }, 100);
    trackRechargeClick(value, item)
  };

  const clearHistory = (url) => {
    history.replace(url);
  };

  const onProceed = (amount, item) => {
    const gpayMode = props?.haveNativeWalletFunctionsOnApp() ? 'native' : 'web'; // native|web
    if (!amount) {
      showError("Please select a plan");
    } else if (amount < 99 && item.plan_type !== 'DEAL') {
      showError("Minimum recharge amount is ₹99.");
    } else if (isLoading) {
      showError("Loading, please wait..");
    } else {
      setIsLoading(true);

      const eData = {
        name: 'Payment Initiate',
        status: 'Initiated',
        amount: amount,
        points: points,
        amountPaid: amount,
        orderId: orderId,
        transactionId: tId,
        gateway: 'CAPG',
        gatewayResponse: 'Unprocessed',
        instantPaymentMethod: instantPaymentMethod,
        walletBalance: walletPoints
      };
      axios()
        .post("/wallet/recharge/initiate-order", {
          plan_id: item.id,
          gateway: 'CAPG',
        })
        .then((res) => {
          console.log({ res })
          if (res.data.success) {
            eData.orderId = res.data.order_id;
            eData.transactionId = res.data.payment_reference_id;
            eData.gatewayResponse = 'Initiated';
            if (res.data.amount) {
              eData.amountPaid = res.data?.amount
            }

            setIsLoading(false);
            if (!(res.data.gateway_url && res.data.gateway_url !== '')) {
              throw new Error('The `gateway_url` is missing');
            } else if (!(res.data.order_id && res.data.order_id !== '')) {
              throw new Error('The `order_id` is missing');
            } else {
              const paymentURL = `${res.data.gateway_url}?order_id=${btoa(res.data.order_id)}&order_checksum=${btoa(res.data.order_checksum)}&gpaymode=${gpayMode}`
              setPaymentDialog({
                open: true,
                paymentURL
              })
              localStorage.setItem('last_order_details', JSON.stringify({
                ...res.data,
                item,
                paymentURL,
                plan_amount: amount,
                instantPaymentMethod: isInstantPaymentRequest(),
                plan_type: item.plan_type,
                id: item.id,
                dealPurchase: DealPurchase.isPaymentRequest() && item.plan_type === 'DEAL' && item.actionRef !== 'wp-recharge-button'
              }))
            }
          } else if (res.data?.error_code === 'ORDER_ERR_103') {
            rechargeRedirect.setItem('recharge', item);
            setTimeout(() => {
              setDealErrorOrder(deal => {
                return {
                  ...deal,
                  open: true,
                  response: res
                }
              })
              setIsLoading(false);
              setBlankBackgroundScreen(false);
            }, 1000);
          } else {
            showError(res.data.message);
            setIsLoading(false);
          }
          trackeventData(eData, item);
        })
        .catch((err) => {
          console.error(err)
          sentryError("MY_WALLET_PROCEED", err);
          showError("E-WLCA-WIO-NR: Error occured, Please try again");
          setIsLoading(false);
          trackeventData(eData, item);
        });

    }
  };

  const getWalletPoints = (amount) => {
    const wallet = walletOptions.find((wallet) => wallet.amount === amount);
    return wallet ? wallet.points : amount;
  }

  const trackeventData = (data, item) => {
    if (!data.amountPaid) {
      data.amountPaid = data.amount;
    }
    if (!data.points) {
      data.points = data.amount;
    }
    const payload = {
      event: {
        name: data?.name || 'Payment Initiate',
        attributes: {
          'Screen Name': (data.instantPaymentMethod) ? 'Instant popup' : 'My Wallet',
          'Status': data.status,
          'SKU': getSKU(data.amount, item),
          'Order ID': data.orderId,
          'Transaction ID': data.transactionId,
          'Price': parseFloat(data.amountPaid),
          'Wallet Amount': parseFloat(data.points),
          'Gateway': data.gateway,
          'Gateway Response': data.gatewayResponse,
          'Cashback': 0,
          'Discount': parseFloat(data.points) - parseFloat(data.amount),
          'Source Screen Name': rechargeRedirect.getItem('location') || 'My Wallet',
          'Reward Used': 0,
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'Last pay attempt status': data.status,
          'Last pay attempt time stamp': Date.now(),
          'COT wallet balance': parseInt(data.walletBalance),
          'Current wallet balance': parseInt(data.walletBalance)
        }
      }
    }

    console.log(payload);
    T2AEventTrack(payload.event, payload.extra);
  }

  const paymentFailedBeforeGateway = (data) => {
    const payload = {
      event: {
        name: 'Screen View Action',
        attributes: {
          'Screen Name': 'Payment Failed Before Gateway',
          'Source Screen Name': rechargeRedirect.getItem('location') || 'My Wallet',
          'Devdata': JSON.stringify(data),
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      }
    }
    console.log(payload);
    T2AEventTrack(payload.event, payload.extra);
  }

  const getSKU = (amount, item) => {
    return 'COT' + (item?.plan_type && item?.plan_type !== 'RECHARGE' ? item.plan_type : '') + amount
  }

  const trackRechargeClick = (amount, item) => {
    const payload = {
      event: {
        name: 'Buy Now Action',
        attributes: {
          'Screen Name': 'My Wallet',
          'Button Name': 'Recharge',
          'Target Screen Name': 'Payment Popup',
          'Source Screen Name': rechargeRedirect.getItem('location') || 'My Wallet',
          'Button Location': 'My wallet',
          'SKU': getSKU(amount, item),
          'Price': amount,
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      }
    }
    console.log(payload);
    T2AEventTrack(payload.event, payload.extra);
  }

  const isInstantPaymentRequest = () => {
    return (rechargeRedirect.isValid() &&
      rechargeRedirect.getItem('instantPayment') &&
      rechargeRedirect.getItem('amount'))
  }

  const tryDealPurchase = () => {
    console.log('isDealPurchaseRequest:', DealPurchase.isPaymentRequest())
    if (DealPurchase.isPaymentRequest()) {

      setBlankBackgroundScreen(true)
      const deal = rechargeRedirect.getItem('recharge')
      DealPurchase.InitiatePayment({
        gateway: 'CAPG', // CAPG, RZ-M (test razorpay mobile)
        setAmount,
        setPoints,
        setAmountPaid,
        setIsLoading,
        trackeventData,
        showError,
        setPaymentDialog,
        setBlankBackgroundScreen,
        deal,
        setDealErrorOrder
      })
    }
  }

  const tryInstantPayment = () => {
    const gpayMode = props.haveNativeWalletFunctionsOnApp() ? 'native' : 'web'; // native|web
    if (isInstantPaymentRequest()) {
      //console.log('recharge', rechargeRedirect.getItem('recharge'))
      const instantPayData = rechargeRedirect.getItem('recharge')
      const instAmount = rechargeRedirect.getItem('amount')

      const eData = {
        name: 'Payment Initiate',
        instantPaymentMethod: true,
        status: 'Initiated',
        amount: instantPayData.amount || instAmount,
        orderId: '',
        transactionId: '',
        gateway: 'CAPG',
        gatewayResponse: 'Unprocessed',
        walletBalance: walletPoints,
        points: instantPayData.actualAmount || instAmount,
        amountPaid: instantPayData.amount
      };
      setAmount(eData.amount);
      setPoints(eData.points);
      axios()
        .post("/wallet/recharge/initiate-order", {
          minutes: rechargeRedirect.getItem('minute'),
          astrologer_id: rechargeRedirect.getItem('astrologerID'),
          gateway: 'CAPG',
        })
        .then((res) => {
          if (res.data.success) {
            eData.orderId = res.data.order_id;
            eData.transactionId = res.data.payment_reference_id;
            eData.gatewayResponse = 'Initiated';
            if (res.data.amount) {
              eData.amountPaid = res.data?.amount
              setAmountPaid(eData.amountPaid)
            }

            setIsLoading(false);
            if (!(res.data.gateway_url && res.data.gateway_url !== '')) {
              throw new Error('The `gateway_url` is missing');
            } else if (!(res.data.order_id && res.data.order_id !== '')) {
              throw new Error('The `order_id` is missing');
            } else {
              const paymentURL = `${res.data.gateway_url}?order_id=${btoa(res.data.order_id)}&order_checksum=${btoa(res.data.order_checksum)}&gpaymode=${gpayMode}`
              setPaymentDialog({
                open: true,
                paymentURL,
                fullScreen: true,
              })
              localStorage.setItem('last_order_details', JSON.stringify({
                ...res.data,
                paymentURL,
                plan_amount: rechargeRedirect.getItem('amount'),
                minutes: rechargeRedirect.getItem('minute'),
                astrologer_id: rechargeRedirect.getItem('astrologerID'),
                instantPaymentMethod: isInstantPaymentRequest()
              }))
            }
          } else {
            const instantPayment = isLastOrderIsInstantPayment()
            if (rechargeRedirect.getItem('minute') && rechargeRedirect.getItem('astrologerID') && instantPayment) {

              setOldStatus('success');
              setModalOpen(true);
              setRechargeValue(instantPayment.recharge_points);
              console.log('InstantPaymentErrorOrder:retry', instantPayment)
            } else {
              eData.orderId = res?.data?.order_id || '';
              eData.transactionId = res?.data?.payment_reference_id || '';
              showError(res.data.message);
              setIsLoading(false);
              setInstantPaymentMethod(false);
            }
          }
          trackeventData(eData);
        })
        .catch((err) => {
          sentryError("MY_WALLET_PROCEED", err);
          showError("E-WLCA-WIO-IP:Error occured, Please try again");
          setIsLoading(false);
          setInstantPaymentMethod(false);
          trackeventData(eData);
        });
      return
    }
  }

  const isLastOrderIsInstantPayment = () => {
    let data = localStorage.getItem('last_order_details')
    if (data !== null) {
      data = JSON.parse(data)
      if (data?.instantPaymentMethod) {
        return data
      }
    }
    return false
  }

  const userUpdates = async () => {
    return new Promise((resolve, reject) => {
      axios()
        .post('/profile/updates')
        .then((res) => {
          if (res && res.data.success && res.data.data) {
            resolve(res.data.data)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const handlePopState = () => {

    // if (location.pathname.startsWith("/recharge/success") ||
    //   location.pathname.startsWith("/recharge/fail")) {
    //   return
    // }

    // window.history.pushState(null, null, window.location.pathname)
    // history.push('/app-home')
  }

  const setRefAndEarnModelOpen = (value) => {
    setRefAndEarnModelOpen(value)
  };

  const goToTransaction = () => {
    const eData = eventsPayload;
    eData.event.name = 'Button Action';
    eData.event.attributes['Screen Name'] = 'My Wallet';
    eData.event.attributes['Button Name'] = 'Transactions';

    console.log(eData);
    T2AEventTrack(eData.event, eData.extra);
    // window.location.href = '/invoices';
    //history.push('/invoices')
    setTransactionOpen(true)
  }

  const onRechargeCalculationView = (item) => {
    const eData = eventsPayload
    eData.event.name = 'Button Action'
    eData.event.attributes['Screen Name'] = 'My Wallet'
    eData.event.attributes['Button Name'] = 'View Calculation'
    eData.event.attributes['SKU'] = getSKU(item.amount, item)
    // console.log({ 'on expand': 1, item, eData })
    T2AEventTrack(eData.event, eData.extra)
  }

  const onReviewsShowMore = (review) => {
    const eData = eventsPayload
    eData.event.name = 'Button Action'
    eData.event.attributes['Screen Name'] = 'My Wallet'
    eData.event.attributes['Button Name'] = 'Show more reviews'

    // console.log({ 'on show more': 1, review, eData })
    T2AEventTrack(eData.event, eData.extra)
  }

  const onCreateOrderAfterPaymentFailed = () => {
    let data = localStorage.getItem('last_order_details')
    if (data !== null) {
      data = JSON.parse(data)

      if (data?.instantPaymentMethod) {
        tryInstantPayment()
        return
      } else if (data?.dealPurchase) {
        tryDealPurchase()
        return
      } else if (data?.plan_amount) {
        onSelect(data.plan_amount, data.item)
      }
    }

    setPaymentFailedDialog(dialog => { return { ...dialog, open: false } })
  }

  return (
    <div className={clsx(
      "app-page new-wallet accountParentDiv",
      instantPaymentMethod && !getURLParams('all-recharge-options') ? 'instant-payment' : '',
      DealPurchase.isPaymentRequest() ? 'deal-purchase' : '',
      blankBackgroundScreen && !getURLParams('all-recharge-options') ? 'instant-payment' : '',
    )}>

      <HelmetComponent
        title="My Wallet at Findastro"
        url="https://www.findastro.com/my-wallet"
        description="My Wallet at Findastro- Connect to your Favorite Astrologer at Findastro"
      />

      <div className="app-page__header accountTopArea">
        <IconButton className="app-page__header__back-icon" onClick={goBack}>
          <ChevronLeft />
        </IconButton>
        <div className="app-page__header__title">Wallet</div>
      </div>
      {/* {BrowserUtils.isDevURL() || BrowserUtils.isLocalhost() ? '[Default Wallet]' : ''} */}

      <Button className="wallet__title wallet__transactions_link" onClick={goToTransaction}>Transactions</Button>

      {rechargeRedirect.getItem('astrologerID') ?
        <AstrologerReservedMessage
          astrologer={{
            id: rechargeRedirect.getItem('astrologerID'),
            name: rechargeRedirect.getItem('astrologerName'),
          }}
          style={{ marginTop: '1.5rem' }}
        />
        :
        null
      }

      <div className="wallet__info">
        <div className="wallet__title">Your wallet balance:</div>
        <div className="wallet__info__points">{walletPoints}<sub>pts</sub></div>
        <ins className="wallet__info__ins">1 Point = ₹1</ins>
      </div>

      <div className="recharge-options">
        <div className="wallet__title">Recharge Options:</div>
        <div className="recharge-options">

          {isRechargeOptionsLoading ?
            <div className="recharge-preload">
              {[1, 2, 3].map(i => <Skeleton key={i} animation="wave">
                <RechargeOptionItem
                  amount="500"
                  points="550"
                  gst={true}
                  type="wallet-item"
                  rechargeButtonExpand={true}
                  showOptionLabel={true}
                />
              </Skeleton>)}
            </div>
            :
            <div className="card-v2__options_option recharge-option">
              {walletOptions
                .map(item =>
                  <RechargeOptionItem
                    {...item}
                    key={item.id}
                    type="wallet-item"
                    rechargeClick={() => onSelect(item.amount, item)} // { ...item, actionRef: 'wp-recharge-button' }
                    highlight={item.amount === highlightPlan}
                    onExpand={onRechargeCalculationView}
                    rechargeButtonExpand={true}
                    showOptionLabel={true}
                  />
                )}
            </div>
          }
        </div>
      </div>

      <div className="secure-payment">
        <img style={{ maxWidth: '100%', height: 'auto' }} src={paymentImage} alt="Secure Payment" />
      </div>

      {(reviewsParameter?.astrologer) &&
        <div className="reviews">
          <ReviewsPanel
            reviewsParameter={{
              user_id: reviewsParameter.user_id,
              astrologer: reviewsParameter.astrologer,
              extra: reviewsParameter.extra
            }}
            showMore={false}
            title="User Testimonials"
            noDataMessage=""
          />
        </div>
      }

      {(reviewsParameter?.user_id) &&
        <div className="reviews">
          <ReviewsPanel reviewsParameter={{
            user_id: reviewsParameter.user_id
          }}
            title={reviewsParameter?.astrologer ? '' : 'User Testimonials'}
            excludeAstrologer={reviewsParameter?.astrologer ? reviewsParameter.astrologer : []}
            perPage={4}
            onShowMore={onReviewsShowMore}
          />
        </div>
      }

      <ModalComponent
        modalOpen={modalOpen}
        status={oldStatus}
        orderId={orderId}
        resetHistory={clearHistory}
        goBack={goBack}
        rechargeValue={rechargeValue}
      />

      <Snackbar
        vertical="top"
        horizontal="right"
        message={snackBar.message}
        closeError={closeError}
        open={snackBar.open}></Snackbar>

      <PaymentDialog
        open={paymentDialog.open}
        paymentURL={paymentDialog.paymentURL}
        handleClose={() => {
          if (isInstantPaymentRequest()) {
            setPaymentDialog(dialog => { return { ...dialog, open: false, fullScreen: false } });
            setInstantPaymentMethod(false);
          } else if (DealPurchase.isPaymentRequest()) {
            setPaymentDialog(dialog => { return { ...dialog, open: false, fullScreen: false } });
            setBlankBackgroundScreen(false)
          } else {
            setPaymentDialog(dialog => { return { ...dialog, open: false } });
          }
          setBlankBackgroundScreen(false)
        }}
        fullScreen={paymentDialog.fullScreen}
      />
      {
        status !== 'SUCCESS' &&
        <PaymentFailedDialog
          open={paymentFailedDialog.open}
          paymentURL={paymentFailedDialog.paymentURL}
          handleClose={() => {
            if (isInstantPaymentRequest()) {
              setInstantPaymentMethod(false);
            }
            setPaymentFailedDialog(dialog => { return { ...dialog, open: false } });
            setBlankBackgroundScreen(false)
          }}
          fullScreen={paymentFailedDialog.fullScreen}
          handleCreateOrderAfterPaymentFailed={onCreateOrderAfterPaymentFailed}
          status={status}
        />
      }


      <DealPurchasePaymentResponseDialog
        type={dealErrorOrder.type}
        open={dealErrorOrder.open}
        response={dealErrorOrder.response}
        handleClose={() => {
          setDealErrorOrder(deal => ({ ...deal, open: false }))
        }}
      />

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Transaction
        open={transactionOpen}
        handleClose={() => {
          setTransactionOpen(false);
        }}
      />
    </div>
  );
}

export default WalletPage;
