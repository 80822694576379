import React, { useEffect, useRef, useState } from 'react'
import { Button, Dialog, IconButton, makeStyles } from '@material-ui/core'
import RechargeLinkRedirect, { useRechargeRedirect } from '../../../user/astrologer/components/RechargeLinkRedirect'
import DealPurchase from './DealPurchase'
import BrowserUtils from '../../../user/astrologer/components/UAScripts'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import clsx from 'clsx'
import { ChevronRight, Close, Repeat } from '@material-ui/icons'
import axios from '../../../../common/interceptor'
import sentryError from '../../../../common/sentryError'
import { WalletUtils, setWalletEvent } from '../../../../route/WalletComponent'
import { GatewayConfig } from '../../../../gateway.config'
import T2AEventTrack from "../../../../common/T2AEventTrack";
import DealPurchaseConfig from './DealPurchaseConfig'
import { isDealPurchaseVoucherEnabled } from './DealPurchaseUtils'

const useDealInitiatorStyles = makeStyles({
  dialog: {
    padding: '.8rem',
    overflow: 'visible',
    '& > .MuiDialog-container > .MuiPaper-root': {
      margin: 0,
      maxHeight: '100vh',
      boxShadow: 'none',
      border: '0',
      // transition: '0s',

      maxWidth: 370,
      width: '100%',
      borderRadius: '0.5rem',
      padding: '2rem',

      "@media (max-width: 365px)": {
        padding: '1.5rem',
      },
    },
    '& > .MuiBackdrop-root': {
      // background: '#e2d0b3',
    },
    '&.transparent-page': {
      '& > .MuiBackdrop-root, & > .MuiDialog-container > .MuiPaper-root, & .instant-payment': {
        background: 'transparent',
      },
      '& .new-wallet:not(.instant-payment)': {
        background: '#fff',
      }
    }
  },
  closeWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeBtn: {
    padding: "0.35rem 0.5rem",
    marginTop: "-17px",
    marginBottom: "-10px",
    marginRight: "-21px",
    position: 'relative',
    top: -5,
    height: 36,
    width: 36,
    '& svg': {
      fontSize: '1.25rem',
    }
  },
  ribbon: {
    '--ribbon-size': '1.5rem',
    fontSize: '1rem',
    lineHeight: '1.1rem',
    paddingTop: '0.4rem',
    marginBottom: '1rem',
    "@media (max-width: 365px)": {
      fontSize: '.9rem',
    }
  },
  boxText: {
    padding: "0.5rem 0.75rem",
    background: "var(--clr-light-grey2)",
    borderRadius: "0.5rem",
    margin: "0.25rem 0.75rem",

    "@media (max-width: 365px)": {
      margin: '0.25rem 0.25rem',
    },
  },
  infoText: {
    color: "var(--clr-red2)",
    textAlign: "center",
    marginTop: "1rem",
    fontSize: ".8rem"
  },
  footer: {
    textAlign: "center",
  },
  footerRU: {
    marginTop: "1rem",
  },
  recharge: {
    background: "var(--clr-green) !important",
    color: "#fff",
    fontSize: "var(--fz-large)",
    boxShadow: "none",
    padding: "0.65rem 1.25rem",
    borderRadius: "2rem",
    '& .MuiButton-label': {
      display: "flex",
      alignItems: "center",
      '& .icon': {
        display: 'flex',
      }
    }
  }
})
const DealPurchaseDialog = ({
  astrologer = {}, // astrologer object
  deal = {}, // Deal object
  type = 'chat', // chat/call
  open: parentOpen = false,
  clickFrom = 'Chat or Talk',
  isRepeatUser: isParentRepeatUser = false,
  handleClose: parentHandleClose = () => { },
  refData: parentRefData = {}
}) => {
  const history = useHistory()
  const classes = useDealInitiatorStyles()
  const [open, setOpen] = useState(false)
  const urlHash = 'back-button--deal-dialog'
  const commonRRBtnEle = useRef(null)
  const [commonRRBtnData, setCommonRRBtnData] = useState({})
  const [isRepeatUser, setIsRepeatUser] = useState(false)
  const rechargeRedirect = useRechargeRedirect()
  let sourceScreen = 'Chat or Talk';

  const clickFromToScreenName = {
    chat: 'Chat Screen',
    astrologerRecommendation: 'Chat Screen',
    'Astrologer profile bottom': 'Astrologer profile',
    'Astrologer profile top': 'Astrologer profile',
    'Try another astrologer - Yes, start consultation': 'Chat Screen',
  };

  if (clickFrom) {
    if (clickFromToScreenName[clickFrom]) {
      sourceScreen = clickFromToScreenName[clickFrom];
    } else if (clickFrom.includes('/astrologer-profile')) {
      sourceScreen = 'Astrologer profile';
    }
  }

  useEffect(() => {
    const handleHashChange = (e) => {
      // if (!e.newURL.includes(`#${urlHash}`) && BrowserUtils.isNewUI()) {
      //   setOpen(false)
      // }

      if (e.oldURL.endsWith(`#${urlHash}`)
        && !e.newURL.endsWith('#back-button--wallet-recharge')
        && open === true) {
        setOpen(false)
      }

      // Reset wallet type to default after close the native payment dialog & showing default wallet page
      if (e.oldURL.endsWith('#back-button--wallet-recharge/payment-dialog')
        && open === true
        && (GatewayConfig.dealsGateway !== 'default' && WalletUtils.getType() !== WalletUtils.getDefaultType())
      ) {
        const refData = rechargeRedirect.getAll()
        delete refData.recharge
        delete refData.amount
        delete refData.dealPurchase
        rechargeRedirect.setData(refData)
        setTimeout(() => {
          WalletUtils.setType(WalletUtils.getDefaultType())
        }, 500)
      }
    }

    window.addEventListener('hashchange', handleHashChange)
  })

  useEffect(() => {
    // console.log('parentOpen:', parentOpen)
    if (parentOpen) {
      // runUserUpdates()
      setOpen(true)
    }
  }, [parentOpen])

  useEffect(() => {
    setIsRepeatUser(isParentRepeatUser)
  }, [isParentRepeatUser])

  const runUserUpdates = () => {
    axios()
      .post("/profile/updates")
      .then((res) => {
        if (res && res.data.success) {
          setOpen(true)
          const hasDeals = res.data?.deals?.length > 0
          setIsRepeatUser(!hasDeals);
        }
      })
      .catch((err) => {
        console.log(err)
      });
  };

  useEffect(async () => {
    if (open === true) {
      window.location.hash = urlHash
    } else if (open === false) {
      parentHandleClose()
    }
    console.log("popup old change")
  }, [open])

  useEffect(() => {
    if (open) {
      if (isRepeatUser) {
        trackEventData({
          name: 'Screen View Action',
          screenName: "Deal Claimed Popup",
          location: clickFrom,
          sourceScreenName: sourceScreen,
          astrologerId: astrologer?.id || 0,
          astrologerName: astrologer?.name || ''
        })
      } else {
        trackEventData({
          name: 'Screen View Action',
          screenName: "Deal Claim Popup",
          location: clickFrom,
          sourceScreenName: sourceScreen,
          astrologerId: astrologer?.id || 0,
          astrologerName: astrologer?.name || ''
        })
      }
    }
  }, [open, isRepeatUser])

  const trackEventData = (data) => {
    const payload = {
      event: {
        name: data?.name,
        attributes: {
          'Screen Name': data?.screenName,
          'Source Screen Name': data?.sourceScreenName || 'Chat or Talk',
          'Product': 'COT',
          'Astrologer ID': data?.astrologerId,
          'Astrologer Name': data?.astrologerName,
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      },
      extra: {
        user: {
          //'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      }
    }
    if (data?.buttonName) {
      if (data?.sourceScreenName === "Chat or Talk" && data?.astrologerId) {
        data.location = 'Chat or Talk';
      }
      payload.event.attributes['Button Name'] = data.buttonName;
      payload.event.attributes['Button Location'] = data?.location;
      payload.event.attributes['Target Screen Name'] = data.targetScreenName;
      payload.event.attributes['Price'] = data.price;
      payload.event.attributes['SKU'] = data.sku;
    }
    T2AEventTrack(payload.event, payload.extra)
  }

  const browserBackTrigger = () => {
    if (!window.location.hash.includes(`#${urlHash}`) && open === true && BrowserUtils.isNewUI()) {
      history.goBack()
    }
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return
    }
    browserBackTrigger()
    setOpen(false)
  }

  const handleRechargeNowBtn = () => {

    // Set deals gateway type for deal recharge button click
    if (GatewayConfig.dealsGateway !== 'default') {
      WalletUtils.setType(GatewayConfig.dealsGateway)
    }
    setTimeout(() => {
      let refData = DealPurchase.getRefData({
        astrologer,
        recharge: deal,
        type
      })
      // console.log('refData', {
      //   astrologer,
      //   recharge: deal,
      //   type
      // }, refData)

      // After logged in user
      if (parentRefData?.refID) {
        refData = parentRefData
      }

      if (isDealPurchaseVoucherEnabled()) {
        refData.voucher = 1
      }

      trackEventData({
        name: 'Buy Now Action',
        screenName: "Deal Purchase Popup",
        buttonName: "Recharge Now",
        sourceScreenName: sourceScreen,
        location: clickFrom,
        astrologerId: astrologer?.id || 0,
        astrologerName: astrologer?.name || '',
        sku: getSKU(deal.amount, deal),
        price: deal.amount,
        targetScreenName: (WalletUtils.getType() === 'rz_native') ? 'Native' : 'CA Payment'
      })

      if (commonRRBtnEle?.current) {
        setCommonRRBtnData(refData)
        commonRRBtnEle.current.click()
      }
    }, 500)
  }

  const getSKU = (amount, item) => {
    return 'COT' + (item?.plan_type && item?.plan_type !== 'RECHARGE' ? item.plan_type : '') + amount
  }

  const handleRepeatUserRechargeBtn = () => {
    // history.push('/user-wallet')
    delete parentRefData.recharge
    delete parentRefData.amount
    delete parentRefData.dealPurchase
    delete parentRefData.dealPurchase
    setTimeout(() => {
      let refData = {
        ...parentRefData,
        refID: 'deal-purchase-dialog/recharge-now',
        highlight: 499,
      }

      trackEventData({
        name: 'Buy Now Action',
        screenName: "Deal Claim Popup",
        buttonName: "Recharge Now",
        location: clickFrom,
        sourceScreenName: sourceScreen,
        astrologerId: astrologer?.id || 0,
        astrologerName: astrologer?.name || '',
        sku: getSKU(deal.amount, deal),
        price: deal.amount,
        targetScreenName: (WalletUtils.getType() === 'rz_native') ? 'Native' : 'CA Payment'
      })
      if (commonRRBtnEle?.current) {
        setCommonRRBtnData(refData)
        commonRRBtnEle.current.click()
      }
    }, 500)
  }

  const CloseBtn = () => {
    const handleClose = () => {
      history.goBack()
      setOpen(false)
    }
    return (
      <div className={clsx('close-wrapper', classes.closeWrapper)}>
        <IconButton
          className={clsx('close-btn', classes.closeBtn)}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </div>
    )
  }

  const DialogContent = () => {
    return (
      <>
        <CloseBtn />
        {
          isRepeatUser ?
            <>
              <div className={clsx('ribbon-both', classes.ribbon)}>
                {/* Hi {localStorage.getItem('name')},<br /> */}
                <span>You have already claimed<br />your <b>FREE CONSULTATION</b></span>
              </div>
              <div className={clsx('box', classes.boxText)}>Take a look at your&nbsp;<a className={clsx('anchor-link')} onClick={() => history.push('/user-consultation-history')}><b>consultation history</b></a>
              </div>
              <div className={clsx('box', classes.boxText)}>Recharge to claim your next <b>expert astrology session</b></div>
              <div className={clsx('footer', classes.footer, classes.footerRU)}>
                <Button className={clsx('recharge', classes.recharge)} onClick={handleRepeatUserRechargeBtn}>
                  <div className="text">Recharge now</div>
                  <div className="icon"><ChevronRight /></div>
                </Button>
              </div>
            </> :
            <>
              <div className={clsx('ribbon-both', classes.ribbon)}>
                <span>Get <b>first consultation</b><br />just for ₹{deal.amount}</span>
              </div>
              <div className={clsx('box', classes.boxText)}>Recharge for ₹{deal.amount}</div>
              <div className={clsx('box', classes.boxText)}>{type === 'call' ? 'Call' : 'Chat'} with expert astrologers for <b style={{ display: 'inline-block' }}>{deal.minutes} minutes</b> and get your questions answered.</div>
              <div className={clsx('info', classes.infoText)}>Offer valid only for first time consultation.</div>
              <div className={clsx('footer', classes.footer)}>
                <Button className={clsx('recharge', classes.recharge)} onClick={handleRechargeNowBtn}>
                  <div className="text">Recharge now</div>
                  <div className="icon"><ChevronRight /></div>
                </Button>
              </div>
            </>
        }
      </>
    )
  }

  return (
    <>
      <Dialog
        className={clsx(
          classes.dialog,
          'deal-recharge-dialog',
          // refData?.deal_transparent_page ? 'transparent-page' : '',
        )}
        open={open}
        onClose={handleClose}
      >
        {open === true ? <DialogContent /> : <></>}
      </Dialog>
      <RechargeLinkRedirect
        refData={commonRRBtnData}
        onClick={(e) => {
          e.goToWallet()
        }}
      >
        <button style={{ display: "none" }} ref={commonRRBtnEle}></button>
      </RechargeLinkRedirect>
    </>
  )
}

export default DealPurchaseDialog